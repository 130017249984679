export default {
    "de": {
        "INSURANCE_FLAT": "Administrationspauschale",
        "lessons": "Fahrlektionen",
        "vku": "VKU Kurse",
        "insurance": "Versicherung",
        "app": "Theorie App",
        "voice_status_start": "Start",
        "voice_status_end": "Beendet",
        "voice_status_queue": "Warteschlange",
        "voice_status_taken": "im Gespräch",
        "voice_status_missed": "Verpasst",
        "voice_status_initiated": "Willkommen",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_voicemail": "Sprachnachricht",
        "absences": "Absenzes",
        "exams": "Prüfungen",
        "students": "Schüler",
        "locations": "Standorte",
        "comments": "Kommentare",
        "employment": "Anstellung",
        "course_documents": "Kursdokumente",
        "course_manuals": "Kursanleitungen",
        "All": "Alle",
        "Instructors": "Kursleiter:innen",
        "Kursleiterinnen": "Kursleiter:innen",
        "Teachers": "Fahrlehrer:innen",
        "The {attribute} must contain at least one letter.": "{attribute} muss aus mindestens einem Zeichen bestehen.",
        "The {attribute} must contain at least one number.": "{attribute} muss aus mindestens einer Zahl bestehen.",
        "The {attribute} must contain at least one symbol.": "{attribute} muss aus mindestens einem Sonderzeichen bestehen.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} muss aus mindestens einem Gross- und einem Kleinbuchstaben bestehen.",
        "{attribute} is keine gültige Sprache.": "{attribute} ist keine gültige Sprache.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Das {attribute} ist bereits in einem Datenleck aufgetaucht. Bitte wähle ein anderes {attribute}.",
        "Anmelden": "Anmelden",
        "E-Mail": "E-Mail",
        "Passwort": "Passwort",
        "Passwort vergessen?": "Passwort vergessen?",
        "Eine Woche lang angemeldet bleiben": "Eine Woche lang angemeldet bleiben",
        "Willkommen bei": "Willkommen bei",
        "Passwort zurücksetzen": "Passwort zurücksetzen",
        "Du bekommst von uns einen Link per E-Mail zugesendet, um dein Passwort zurückzusetzen. Bitte gib deine E-Mail-Adresse ein, die du zum Login im Sanio Kundenportal verwendest.": "Du bekommst von uns einen Link per E-Mail zugesendet, um dein Passwort zurückzusetzen. Bitte gib deine E-Mail-Adresse ein, die du zum Login im Sanio Kundenportal verwendest.",
        "Zurück zur Anmeldung": "Zurück zur Anmeldung",
        "Link anfordern": "Link anfordern",
        "zurück": "zurück",
        "Liste exportieren": "Liste exportieren",
        "Anmeldungen": "Anmeldungen",
        "Abmeldungen": "Abmeldungen",
        "Vorname": "Vorname",
        "Nachname": "Nachname",
        "Kurssprache": "Kurssprache",
        "Beschreibung": "Beschreibung",
        "Link ist aktiv": "Link ist aktiv",
        "Kurs freigeben": "Kurs freigeben",
        "Es existieren keine Daten.": "Es existieren keine Daten.",
        "Willkommen": "Willkommen",
        "Teilnehmende": "Teilnehmende",
        "Sortieren nach": "Sortieren nach",
        "Datum": "Datum",
        "Adresse": "Adresse",
        "Link für gesamte Kursliste kopieren": "Link für gesamte Kursliste kopieren",
        "Link für diese Kursgruppe kopieren": "Link für diese Kursgruppe kopieren",
        "Plätze frei": "Plätze frei",
        "ausgebucht": "ausgebucht",
        "Vielen Dank! Wir haben deine Angaben erhalten.": "Vielen Dank! Wir haben deine Angaben erhalten.",
        "Weitere Person anmelden": "Weitere Person anmelden",
        "An-/Abmeldung": "An-/Abmeldung",
        "Ja, ich nehme teil": "Ja, ich nehme teil",
        "Nein, ich bin verhindert": "Nein, ich bin verhindert",
        "Absenden": "Absenden",
        "Du musst die AGB akzeptieren!": "Du musst die AGB akzeptieren!",
        "Fragen oder Probleme?": "Fragen oder Probleme?",
        "Deine Ansprechperson:": "Deine Ansprechperson:",
        "Deine Ansprechpersonen:": "Deine Ansprechpersonen:",
        "Hallo": "Hallo",
        "Anmeldebestätigung": "Anmeldebestätigung",
        "Erste Hilfe Kurs": "Erste Hilfe Kurs",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos",
        "Liebe Grüsse": "Liebe Grüsse",
        "Dein Sanio-Team": "Dein Sanio-Team",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Umbuchungsbestätigung",
        "Terminerinnerung": "Terminerinnerung",
        "Mitarbeitende": "Mitarbeitende",
        "Deutsch": "Deutsch",
        "Französisch": "Französisch",
        "Italienisch": "Italienisch",
        "Englisch": "Englisch",
        "Spanisch": "Spanisch",
        "Portugiesisch": "Portugiesisch",
        "In Kurs verschieben": "In Kurs verschieben",
        "Abbrechen": "Abbrechen",
        "Abmelden": "Abmelden",
        "Kurse": "Kurse",
        "Impersonation beenden": "Impersonation beenden",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Die Datei {fileName} wurde {statusHint} importiert.",
        "teilweise": "teilweise",
        "erfolgreich": "erfolgreich",
        "Zeile": "Zeile",
        "Spalte": "Spalte",
        "Fehlermeldung": "Fehlermeldung",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:",
        "Kontaktdaten": "Kontaktdaten",
        "Ähnliche TN": "Ähnliche TN",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.",
        "Teilnehmerübersicht öffnen": "Teilnehmerübersicht öffnen",
        "Falls du Excel für den Import verwendest, achte darauf, dass Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Falls du Excel für den Import verwendest, achte darauf, dass Spalten wie z.B. das Geburtsdatum als Text formatiert sind.",
        "Email": "E-Mail",
        "Handynummer": "Handynummer",
        "certificates.not-yet-known": "Noch nicht bekannt",
        "certificates.sanio": "Kursbestätigung Sanio",
        "certificates.src": "SRC Zertifikat",
        "certificates.none": "Kein Zertifikat",
        "Kursübersicht": "Kursübersicht",
        "Zurück zum Login": "Zurück zum Login",
        "Passwort bestätigen": "Passwort bestätigen",
        "Dashboard": "Dashboard",
        "In welchen Kurs soll {participantName} verschoben werden?": "In welchen Kurs soll {participantName} verschoben werden?",
        "Es sind keine anderen Kurse verfügbar.": "Es sind keine anderen Kurse verfügbar.",
        "Mehr anzeigen": "Mehr anzeigen",
        "{participantName} in Kurs {courseName} verschieben?": "{participantName} in Kurs {courseName} verschieben?",
        "Zum Kurs": "Zum Kurs",
        "Umbuchungsbestätigung senden": "Umbuchungsbestätigung senden",
        "Zurück": "Zurück",
        "Verschieben": "Verschieben",
        "Willkommen {name} 👋": "Willkommen {name} 👋",
        "Diese Anmeldemaske wurde deaktiviert.": "Diese Anmeldemaske wurde deaktiviert.",
        "errors.4xx.title": "Unerwarteter Fehler",
        "errors.4xx.head": "Uups...",
        "errors.4xx.hint": "Ein Fehler ist aufgetreten. Deine Anfrage konnte nicht verarbeitet werden. Bitte überprüfe deine Eingaben oder versuche es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.5xx.title": "Interner Serverfehler",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Ein unerwarteter Fehler ist aufgetreten. Gehe zurück zur Startseite oder versuche es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.401.title": "Zugriff verweigert",
        "errors.401.head": "Oops…",
        "errors.401.hint": "Du bist nicht berechtigt, auf diese Seite zuzugreifen. Bitte melde dich an und versuche es erneut.",
        "errors.403.title": "Zugriff verweigert",
        "errors.403.head": "Oops…",
        "errors.403.hint": "Zugriff verweigert. Du hast keine Berechtigung, diese Seite zu besuchen. Bitte wende dich an uns, wenn du glaubst, dass dies ein Fehler ist.",
        "errors.404.title": "Seite nicht gefunden",
        "errors.404.head": "Uups...",
        "errors.404.hint": "Die von Ihnen aufgerufene Seite existiert nicht. Gehe zurück oder klicke auf den Button, um auf die Startseite zu gelangen.",
        "errors.500.title": "Interner Serverfehler",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "Ein unerwarteter Fehler ist aufgetreten. Gehe zurück zur Startseite oder versuche es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.503.title": "Wartungsmodus",
        "errors.503.head": "Einen Moment bitte...",
        "errors.503.hint": "Wir führen derzeit Wartungsarbeiten durch, um Verbesserungen und Fehlerbehebungen für eine bessere Nutzererfahrung vorzunehmen. Bitte versuche es in Kürze erneut. Vielen Dank für dein Verständnis.",
        "errors.503.hint2": "In dringenden Fällen sind wir per E-Mail unter <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> oder telefonisch unter <a href=\"tel:+41315212409\">031 521 24 09</a> erreichbar.",
        "errors.504.title": "Timeout",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "Die Anfrage hat zu lange gedauert. Bitte versuche es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.back-to-start": "Zur Startseite",
        "Fehler beim Laden der Kurse": "Fehler beim Laden der Kurse",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Du wurdest in den Kurs «{courseName}» umgebucht.",
        "Die aktuellen Infos lauten:": "Hier die neuen Infos:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:",
        "oder": "oder",
        "Kursort:": "Kursort:",
        "Kursdaten": "Kursdaten",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Bald ist es soweit und der Kurs «{courseName}» findet statt.",
        "Hier nochmals die wichtigsten Infos:": "Hier nochmals die wichtigsten Infos:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉",
        "Kurs-ID": "Kurs-ID",
        "Zum Kalender hinzufügen": "Zum Kalender hinzufügen",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.",
        "Die E-Mail-Adresse ist ungültig.": "Die E-Mail-Adresse ist ungültig",
        "Die Handynummer ist ungültig.": "Die Handynummer ist ungültig.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Mit dieser E-Mail kannst du dich leider nicht anmelden.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.",
        "Der Code ist ungültig.": "Der Code ist ungültig.",
        "Du musst die AGB akzeptieren, um fortfahren zu können.": "Du musst die AGB akzeptieren, um fortfahren zu können.",
        "Bitte gib eine gültige Handynummer ein.": "Bitte gib eine gültige Handynummer ein.",
        "Text wurde in die Zwischenablage kopiert": "Text wurde in die Zwischenablage kopiert",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Text konnte nicht in die Zwischenablage kopiert werden",
        "Der Katalog wurde erstellt.": "Der Katalog wurde erstellt.",
        "Der Katalog wurde aktualisiert.": "Der Katalog wurde aktualisiert.",
        "Der Katalog wurde entfernt.": "Der Katalog wurde entfernt.",
        "Das Thema wurde erstellt.": "Das Thema wurde erstellt.",
        "Das Thema wurde aktualisiert.": "Das Thema wurde aktualisiert.",
        "Das Thema wurde entfernt.": "Das Thema wurde entfernt.",
        "Der Kurs wurde dupliziert 🎉": "Der Kurs wurde dupliziert 🎉",
        "Kurs": "Kurs",
        "Story wurde erfolgreich kopiert 🎉": "Story wurde erfolgreich kopiert 🎉",
        "Eine Variante|{count} Varianten": "Eine Variante|{count} Varianten",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.",
        "Geschlecht": "Geschlecht",
        "Männlich": "Männlich",
        "Weiblich": "Weiblich",
        "Divers": "Divers",
        "Projektstandort": "Projektstandort",
        "Wohnkanton": "Wohnkanton",
        "Sanio Logo": "Sanio Logo",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.",
        "hier": "hier",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.",
        "Dein Sanio Team": "Dein Sanio Team",
        "Hallo {name} 👋": "Hallo {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Hier sind nochmals die wichtigsten Details:",
        "Teilnehmer:innen": "Teilnehmer:innen",
        "Name": "Name",
        "Letzter Kurs": "Letzter Kurs",
        "Nächster Kurs": "Nächster Kurs",
        "Hinzufügen": "Hinzufügen",
        "Exportieren": "Exportieren",
        "Zu Kurs zugeordnet": "Zu Kurs zugeordnet",
        "Keinem Kurs zugeordnet": "Keinem Kurs zugeordnet",
        "Suchbegriff": "Suchbegriff",
        "Alle": "Alle",
        "Ungruppierte Kurse": "Ungruppierte Kurse",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.",
        "Anmeldebestätigung erneut zustellen": "Anmeldebestätigung erneut zustellen",
        "Einladung zum eLearning": "Einladung zum eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "Das eLearning dauert insgesamt etwa 2 Stunden.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Teilnehmer:in abmelden / aus Kurs entfernen",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?",
        "Abmelden / entfernen": "Abmelden / entfernen",
        "eLearning Einladung verschicken": "eLearning Einladung verschicken",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Willst du wirklich eine eLearning Einladung verschicken?",
        "Einladung verschicken": "Einladung verschicken",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Willst du wirklich die Anmeldebestätigung erneut zustellen?",
        "Bestätigung erneut verschicken": "Bestätigung erneut verschicken",
        "Teilnehmer:in abmelden": "Teilnehmer:in abmelden",
        "Bemerkungen": "Bemerkungen",
        "Wert fehlt!": "Wert fehlt!",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.",
        "Zu Kursgruppe hinzufügen": "Zu Kursgruppe hinzufügen",
        "Neue Kursgruppe erstellen": "Neue Kursgruppe erstellen",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.",
        "Freigabelink löschen": "Freigabelink löschen",
        "Möchtest du den Freigabelink wirklich löschen?": "Möchtest du den Freigabelink wirklich löschen?",
        "Archivieren": "Archivieren",
        "Endgültig löschen": "Endgültig löschen",
        "Gruppe löschen": "Gruppe löschen",
        "Möchtest du diese Gruppe wirklich löschen?": "Möchtest du diese Gruppe wirklich löschen?",
        "Kurs aus Gruppe entfernen": "Kurs aus Gruppe entfernen",
        "Kurs duplizieren": "Kurs duplizieren",
        "Kurs archivieren": "Kurs archivieren",
        "Kurs löschen": "Kurs löschen",
        "Der Link wurde in die Zwischenablage kopiert! 🎉": "Der Link wurde in die Zwischenablage kopiert! 🎉",
        "Der Freigabelink wurde gelöscht! 🎉": "Der Freigabelink wurde gelöscht! 🎉",
        "Link teilen": "Link teilen",
        "Gruppe bearbeiten": "Gruppe bearbeiten",
        "Neue:n Teilnehmer:in anmelden": "Neue:n Teilnehmer:in anmelden",
        "nimmt teil": "nimmt teil",
        "Speichern": "Speichern",
        "Ähnliche Teilnehmer:innen": "Ähnliche Teilnehmer:innen",
        "Ein unerwarteter Fehler ist aufgetreten": "Ein unerwarteter Fehler ist aufgetreten",
        "Bereits in diesem Kurs vorhanden": "Bereits in diesem Kurs vorhanden",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "Es wurden keine ähnlichen Teilnehmer:innen gefunden.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Fülle das Formular aus und die Suche wird gestartet.",
        "Suche ähnliche Teilnehmer:innen...": "Suche ähnliche Teilnehmer:innen...",
        "eLearning nicht gefunden": "eLearning nicht gefunden",
        "Wie hat dir das eLearning allgemein gefallen?": "Wie hat dir das eLearning allgemein gefallen?",
        "🤩 Sehr gut!": "🤩 Sehr gut!",
        "😊 Gut": "😊 Gut",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Nicht so gut",
        "😩 Schlecht": "😩 Schlecht",
        "Bitte wähle eine Antwort aus.": "Bitte wähle eine Antwort aus.",
        "Wie verständlich waren für dich die Lerninhalte?": "Wie verständlich waren für dich die Lerninhalte?",
        "🤩 Sehr verständlich!": "🤩 Sehr verständlich!",
        "😊 Verständlich": "😊 Verständlich",
        "😕 Nicht so verständlich": "😕 Nicht so verständlich",
        "😩 Gar nicht verständlich": "😩 Gar nicht verständlich",
        "Wie waren die Aufgaben in den Notfällen für dich?": "Wie waren die Aufgaben in den Notfällen für dich?",
        "🤩 Sehr einfach!": "🤩 Sehr einfach!",
        "😊 einfach": "😊 einfach",
        "😐 Nicht so einfach": "😐 Nicht so einfach",
        "😕 Schwierig": "😕 Schwierig",
        "😩 Sehr schwierig": "😩 Sehr schwierig",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?",
        "🤩 Super einfache Bedienung!": "🤩 Super einfache Bedienung!",
        "😊 Einfach zu bedienen": "😊 Einfach zu bedienen",
        "😐 Teilweise etwas mühsam": "😐 Teilweise etwas mühsam",
        "😩 Richtig mühsame Bedienung": "😩 Richtig mühsame Bedienung",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "Wie schwierig war für dich die Lernkontrolle am Ende?",
        "Warum war die Lernkontrolle für dich einfach?": "Warum war die Lernkontrolle für dich einfach?",
        "Überspringen": "Überspringen",
        "Warum war die Lernkontrolle für dich schwierig?": "Warum war die Lernkontrolle für dich schwierig?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "Wie können wir das eLearning verbessern? Was hat dir gefehlt?",
        "Würdest du das eLearning weiterempfehlen?": "Würdest du das eLearning weiterempfehlen?",
        "🤩 Ja klar!": "🤩 Ja klar!",
        "😊 Ja, wahrscheinlich schon": "😊 Ja, wahrscheinlich schon",
        "😐 Weiss nicht recht": "😐 Weiss nicht recht",
        "😕 Eher nicht": "😕 Eher nicht",
        "😩 Nein, auf keinen Fall": "😩 Nein, auf keinen Fall",
        "Vielen Dank für deine Antworten!": "Vielen Dank für deine Antworten!",
        "Cool hast du dir die Zeit genommen!": "Cool hast du dir die Zeit genommen!",
        "Fehler beim Laden der Suchergebnisse": "Fehler beim Laden der Suchergebnisse",
        "Jetzt mit dem eLearning starten": "Jetzt mit dem eLearning starten",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa {duration} ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa {duration} ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!",
        "Dein Verifizierungscode lautet: <strong>{code}</strong>": "Dein Verifizierungscode lautet: <strong>{code}</strong>",
        "Teilnehmer:innen {company}": "Teilnehmer:innen {company}",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, eLearning für den Kurs von {company} nicht vergessen!",
        "Dein my.sanio Verifizierungscode": "Dein my.sanio Verifizierungscode",
        "Dein Sanio-Verifizierungscode": "Dein Sanio-Verifizierungscode",
        "allgemeinen Geschäftsbedingungen": "allgemeinen Geschäftsbedingungen",
        "Datenschutzerklärung": "Datenschutzerklärung",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "AGB und Datenschutz müssen akzeptiert werden.",
        "Der Kurs ist leider bereits ausgebucht.": "Der Kurs ist leider bereits ausgebucht.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Wir konnten dich leider nicht am Kurs anmelden.",
        "Persönliche Daten": "Persönliche Daten",
        "Profil": "Profil",
        "Zukünftige Kurse": "Zukünftige Kurse",
        "Vergangene Kurse": "Vergangene Kurse",
        "eLearning": "eLearning",
        "Fortschritt:": "Fortschritt:",
        "Dauer:": "Dauer:",
        "Kommunikation": "Kommunikation",
        "unbekannter Betreff": "unbekannter Betreff",
        "Zeit": "Zeit",
        "Status": "Status",
        "Kanal": "Kanal",
        "Inhalt": "Inhalt",
        "Von:": "Von:",
        "An:": "An:",
        "CC:": "CC:",
        "BCC:": "BCC:",
        "Antwort an:": "Antwort an:",
        "Anhänge": "Anhänge",
        "Nicht dargestellte Kursdaten": "Nicht dargestellte Kursdaten",
        "Benutzerbild": "Benutzerbild",
        "Meine Kurse": "Meine Kurse",
        "Dashboard Instruktor:innen": "Dashboard Instruktor:innen",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).",
        "Jetzt einloggen": "Jetzt einloggen",
        "Link zum Einloggen": "Link zum Einloggen",
        "Teilnahme-Status erfolgreich aktualisiert.": "Teilnahme-Status erfolgreich aktualisiert.",
        "Team": "Team",
        "Anwesenheit": "Anwesenheit",
        "Gemäss Kursleiter:in anwesend": "Gemäss Kursleiter:in anwesend",
        "Gemäss Kursleiter:in abwesend": "Gemäss Kursleiter:in abwesend",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 Du hast diese Woche keine Kurse.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 Du hast keine zukünftigen Kurse.",
        "😌 Du hast keine vergangenen Kurse.": "😌 Du hast keine vergangenen Kurse.",
        "Diese Woche": "Diese Woche",
        "und": "und",
        "Geschäftsführerin Sanio AG": "Geschäftsführerin Sanio AG",
        "Zertifikat": "Zertifikat",
        "Teilnahmebestätigung": "Teilnahmebestätigung",
        "am <strong>{date}</strong>": "am <strong>{date}</strong>",
        "am <strong>{dateStart}</strong> und <strong>{dateEnd}</strong>": "am <strong>{dateStart}</strong> und <strong>{dateEnd}</strong>",
        "vom <strong>{dateStart}</strong> bis <strong>{dateEnd}</strong>": "vom <strong>{dateStart}</strong> bis <strong>{dateEnd}</strong>",
        "Kursleitung:": "Kursleitung:",
        "Dieses Zertifikat ist bis am {date} gültig.": "Dieses Zertifikat ist bis am {date} gültig.",
        "Eine Auffrischung der Erste Hilfe Grundlagen alle 2 Jahre wird empfohlen.": "Eine Auffrischung der Erste Hilfe Grundlagen alle 2 Jahre wird empfohlen.",
        "Download wird gestartet...": "Download wird gestartet...",
        "Download erfolgreich abgeschlossen.": "Download erfolgreich abgeschlossen.",
        "Download fehlgeschlagen.": "Download fehlgeschlagen.",
        "Generiere Teilnahmebestätigungen...": "Generiere Teilnahmebestätigungen...",
        "Teilnahmebestätigungen wurden erfolgreich generiert und heruntergeladen.": "Teilnahmebestätigungen wurden erfolgreich generiert und heruntergeladen.",
        "Fehler beim Erstellen der Teilnahmebestätigungen.": "Fehler beim Erstellen der Teilnahmebestätigungen.",
        "Generiere Teilnahmebestätigung...": "Generiere Teilnahmebestätigung...",
        "Teilnahmebestätigung wurde erfolgreich generiert und heruntergeladen.": "Teilnahmebestätigung wurde erfolgreich generiert und heruntergeladen.",
        "Fehler beim Erstellen der Teilnahmebestätigung.": "Fehler beim Erstellen der Teilnahmebestätigung.",
        "Teilnehmerliste exportieren": "Teilnehmerliste exportieren",
        "Teilnahmebestätigungen als PDF": "Teilnahmebestätigungen als PDF",
        "Teilnahmebestätigungen als ZIP": "Teilnahmebestätigungen als ZIP",
        "wurde erstellt": "wurde erstellt",
        "kommentierte": "kommentierte",
        "Bearbeiten": "Bearbeiten",
        "Löschen": "Löschen",
        "Schreibe deinen Kommentar...": "Schreibe deinen Kommentar...",
        "Zurücksetzen": "Zurücksetzen",
        "Kommentieren": "Kommentieren",
        "Kommentar bearbeiten": "Kommentar bearbeiten",
        "Aktivität": "Aktivität",
        "Teilnehmer:in hinzufügen": "Teilnehmer:in hinzufügen",
        "Teilnahmebestätigung herunterladen": "Teilnahmebestätigung herunterladen",
        "Teilnehmer abgleichen": "Teilnehmer abgleichen",
        "Dieses Objekt": "Dieses Objekt",
        "Der Kurs ist ausgebucht.": "Der Kurs ist ausgebucht.",
        "abgeschlossen": "abgeschlossen",
        "Der Kurs ist bereits beendet.": "Der Kurs ist bereits beendet.",
        "Keine Teilnehmenden|ein:e Teilnehmer:in|{capacity} Teilnehmende": "Keine Teilnehmenden|ein:e Teilnehmer:in|{capacity} Teilnehmende",
        "{availableSpots} Plätze frei": "{availableSpots} Plätze frei",
        "Teilnehmer wurde in neuen Kurs verschoben.": "Teilnehmer wurde in neuen Kurs verschoben.",
        "Beendete Kurse können nicht mehr bearbeitet werden.": "Beendete Kurse können nicht mehr bearbeitet werden.",
        "Kurstyp wurde erfolgreich aktualisiert.": "Kurstyp wurde erfolgreich aktualisiert.",
        "Kurse wurden der Gruppe hinzugefügt.": "Kurse wurden der Gruppe hinzugefügt.",
        "Der Benutzer wurde erfolgreich erstellt.": "Der Benutzer wurde erfolgreich erstellt.",
        "vollständig": "vollständig",
        "Möchtest du das Element wirklich löschen?": "Möchtest du das Element wirklich löschen?",
        "Auswählen": "Auswählen",
        "Teilnehmer:in anzeigen": "Teilnehmer:in anzeigen",
        "Nachrichtenverlauf": "Nachrichtenverlauf",
        "Kurs erstellen": "Kurs erstellen",
        "Kurstypen": "Kurstypen",
        "Keine Daten gefunden.": "Keine Daten gefunden.",
        "Kurstyp bearbeiten": "Kurstyp bearbeiten",
        "Kurs hat noch nicht begonnen.": "Kurs hat noch nicht begonnen.",
        "Kurs bearbeiten": "Kurs bearbeiten",
        "HINWEIS:": "HINWEIS:",
        "Die Offerte wurde erstellt.": "Die Offerte wurde erstellt.",
        "Das Angebot wurde aktualisiert.": "Das Angebot wurde aktualisiert.",
        "Das Angebot wurde entfernt.": "Das Angebot wurde entfernt.",
        "Der Import wurde gestartet und wird im Hintergrund ausgeführt.": "Der Import wurde gestartet und wird im Hintergrund ausgeführt.",
        "Teilnehmer erfolgreich gespeichert!": "Teilnehmer erfolgreich gespeichert!",
        "Teilnehmer wurde gelöscht!": "Teilnehmer wurde gelöscht!",
        "Nachricht wurde erneut zugestellt 🎉": "Nachricht wurde erneut zugestellt 🎉",
        "Das Login wurde erstellt! 🎉": "Das Login wurde erstellt! 🎉",
        "Das Login wurde gelöscht! 🎉": "Das Login wurde gelöscht! 🎉",
        "Der Kundenbereich wurde gespeichert! 🎉": "Der Kundenbereich wurde gespeichert! 🎉",
        "Nachricht wurde verschickt 🎉": "Nachricht wurde verschickt 🎉",
        "Kursgruppe erfolgreich erstellt 🎉": "Kursgruppe erfolgreich erstellt 🎉",
        "Kursgruppe erfolgreich aktualisiert 🎉": "Kursgruppe erfolgreich aktualisiert 🎉",
        "Kursgruppe wurde gelöscht!": "Kursgruppe wurde gelöscht!",
        "Geteilter Link gelöscht!": "Geteilter Link gelöscht!",
        "Teilnehmer wurde in den neuen Kurs verschoben.": "Teilnehmer wurde in den neuen Kurs verschoben.",
        "Der Kurs wurde gespeichert 🎉": "Der Kurs wurde gespeichert 🎉",
        "Der Kurs wurde gespeichert": "Der Kurs wurde gespeichert",
        "Der Kurs wurde erfolgreich gelöscht!": "Der Kurs wurde erfolgreich gelöscht!",
        "Der Kurs wurde erfolgreich archiviert!": "Der Kurs wurde erfolgreich archiviert!",
        "Teilnehmer:in erfolgreich abgemeldet.": "Teilnehmer:in erfolgreich abgemeldet.",
        "Der Benutzer wurde erfolgreich aktualisiert.": "Der Benutzer wurde erfolgreich aktualisiert.",
        "Der Benutzer wurde erfolgreich gelöscht.": "Der Benutzer wurde erfolgreich gelöscht.",
        "Die Weiterleitung wurde erstellt!": "Der Kurs wurde erfolgreich archiviert!",
        "Die Weiterleitung wurde aktualisiert!": "Die Weiterleitung wurde aktualisiert!",
        "Die Weiterleitung wurde gelöscht!": "Die Weiterleitung wurde gelöscht!",
        "Mail Statusinformationen wurden aktualisiert.": "Mail Statusinformationen wurden aktualisiert.",
        "Das Unternehmen wurde erstellt.": "Das Unternehmen wurde erstellt.",
        "Das Unternehmen wurde aktualisiert.": "Das Unternehmen wurde aktualisiert.",
        "Das Unternehmen wurde gelöscht!": "Das Unternehmen wurde gelöscht!",
        "Der Kontakt wurde aktualisiert.": "Der Kontakt wurde aktualisiert.",
        "Der Kontakt wurde entfernt.": "Der Kontakt wurde entfernt.",
        "Der Kundenbereich wurde eröffnet! 🎉": "Der Kundenbereich wurde eröffnet! 🎉",
        "Der Kommentar wurde gespeichert.": "Der Kommentar wurde gespeichert.",
        "Der Kommentar wurde aktualisiert.": "Der Kommentar wurde aktualisiert.",
        "Der Kommentar wurde entfernt.": "Der Kommentar wurde entfernt.",
        "Der Kontakt wurde erstellt.": "Der Kontakt wurde erstellt.",
        "Willkommen im": "Willkommen im",
        "Instruktor:innen Bereich": "Instruktor:innen Bereich",
        "Der Login-Link ist ungültig oder abgelaufen.": "Der Login-Link ist ungültig oder abgelaufen.",
        "weiter": "weiter",
        "+{more} weitere": "+{more} weitere",
        "Telefonzeiten": "Telefonzeiten",
        "Montag bis Freitag": "Montag bis Freitag",
        "9.00 bis 12.00 Uhr": "9.00 bis 12.00 Uhr",
        "13.00 bis 16.00 Uhr": "13.00 bis 16.00 Uhr",
        "Notfallnummer (für Kurse)": "Notfallnummer (für Kurse)",
        "Link für diesen Kurs kopieren": "Link für diesen Kurs kopieren",
        "Link für Anmeldung teilen": "Link für Anmeldung teilen",
        "🤔 Es sind keine Kurse geplant.": "🤔 Es sind keine Kurse geplant.",
        "Suchen...": "Suchen...",
        "Kursinstruktor:in": "Kursinstruktor:in",
        "Management": "Management",
        "Büro": "Büro",
        "Schule": "Schule",
        "Berufsschule": "Berufsschule",
        "Gymnasium": "Gymnasium",
        "Kita": "Kita",
        "Betrieb": "Betrieb",
        "Vereine": "Vereine",
        "Sonstiges": "Sonstiges",
        "Teilnahmebestätigung Erste Hilfe Kurs": "Teilnahmebestätigung Erste Hilfe Kurs",
        "Zertifikat BLS AED SRC Kurs": "Zertifikat BLS AED SRC Kurs",
        "Wir freuen uns, dir dein Zertifikat für den <strong>{courseName}</strong> zu übermitteln.": "Wir freuen uns, dir dein Zertifikat für den <strong>{courseName}</strong> zu übermitteln.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für den <strong>{courseName}</strong> zu übermitteln.": "Wir freuen uns, dir deine Teilnahmebestätigung für den <strong>{courseName}</strong> zu übermitteln.",
        "Die Datei findest du im Anhang dieser E-Mail.": "Die Datei findest du im Anhang dieser E-Mail.",
        "Herzlichen Glückwunsch und weiterhin viel Erfolg!": "Herzlichen Glückwunsch und weiterhin viel Erfolg!",
        "Zertifikate werden nun generiert und an die Teilnehmer:innen verschickt 🎉": "Zertifikate werden nun generiert und an die Teilnehmer:innen verschickt 🎉",
        "Zertifikat wird nun generiert und an den/die Teilnehmer:in verschickt 🎉": "Zertifikat wird nun generiert und an den/die Teilnehmer:in verschickt 🎉",
        "Teilnahmebestätigungen an Teilnehmer versenden": "Teilnahmebestätigungen an Teilnehmer versenden",
        "Teilnahmebestätigungen per Mail zustellen": "Teilnahmebestätigungen per Mail zustellen",
        "Möchtest du die Teilnahmebestätigungen an alle Teilnehmer:innen zustellen?": "Möchtest du die Teilnahmebestätigungen an alle Teilnehmer:innen zustellen?",
        "ACHTUNG:": "ACHTUNG:",
        "Für einige Teilnehmer:innen, die Anspruch auf eine Teilnahmebestätigung haben, liegt keine E-Mail-Adresse vor. Daher kann die Bestätigung an die folgenden Personen nicht per Mail zugestellt werden:": "Für einige Teilnehmer:innen, die Anspruch auf eine Teilnahmebestätigung haben, liegt keine E-Mail-Adresse vor. Daher kann die Bestätigung an die folgenden Personen nicht per Mail zugestellt werden:",
        "Bestätigung zustellen": "Bestätigung zustellen",
        "Teilnahmebestätigung per Mail versenden": "Teilnahmebestätigung per Mail versenden",
        "Willst du wirklich die Teilnahmebestätigung per Mail versenden?": "Willst du wirklich die Teilnahmebestätigung per Mail versenden?",
        "Füge deine E-Mail-Adresse ein, um eine Anmeldebestätigung und ein Erinnerungsmail zu erhalten.": "Füge deine E-Mail-Adresse ein, um eine Anmeldebestätigung und ein Erinnerungsmail zu erhalten.",
        "Füge eine persönliche E-Mail-Adresse ein, damit der:die Teilnehmer:in alle kursbezogenen Mitteilungen (z. B. Anmeldebestätigung, Erinnerung, Teilnahmebestätigung) direkt per Mail erhält.": "Füge eine persönliche E-Mail-Adresse ein, damit der:die Teilnehmer:in alle kursbezogenen Mitteilungen (z. B. Anmeldebestätigung, Erinnerung, Teilnahmebestätigung) direkt per Mail erhält.",
        "Sanio Kundenbereich": "Sanio Kundenbereich",
        "Liebe Geschäftskund:innen, unser Portal für die Kursverwaltung hat einen neuen Namen. Das {portalName} erreicht ihr ab sofort unter {sanioPortalLink}.": "Liebe Geschäftskund:innen, unser Portal für die Kursverwaltung hat einen neuen Namen. Das {portalName} erreicht ihr ab sofort unter {sanioPortalLink}.",
        "Teilnahmebestätigungen herunterladen": "Teilnahmebestätigungen herunterladen",
        "Hier dein persönlicher Zugang, um dich im my.sanio einzuloggen:": "Hier dein persönlicher Zugang, um dich im my.sanio einzuloggen:",
        "Wichtige Hinweise:": "Wichtige Hinweise:",
        "Der Link kann nur einmal verwendet werden.": "Der Link kann nur einmal verwendet werden.",
        "Er ist zeitlich begrenzt gültig.": "Er ist zeitlich begrenzt gültig.",
        "Falls das Login nicht funktioniert, kannst du jederzeit einen neuen Link auf der <a href=\"{loginPageUrl}\">Anmeldeseite</a> anfordern.": "Falls das Login nicht funktioniert, kannst du jederzeit einen neuen Link auf der <a href=\"{loginPageUrl}\">Anmeldeseite</a> anfordern.",
        "Hier dein persönlicher Zugang, um dich im Sanio Kundenportal einzuloggen:": "Hier dein persönlicher Zugang, um dich im Sanio Kundenportal einzuloggen:",
        "Hier dein persönlicher Zugang, um dich im Sanio Instruktor:innen Bereich einzuloggen:": "Hier dein persönlicher Zugang, um dich im Sanio Instruktor:innen Bereich einzuloggen:",
        "in Planung": "in Planung",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im Sanio Kundenportal mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Ist ein Passwort hinterlegt, kann sich der Kontakt im Sanio Kundenportal mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im team.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Ist ein Passwort hinterlegt, kann sich der Kontakt im team.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im my.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Ist ein Passwort hinterlegt, kann sich der Kontakt im my.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.",
        "Einzelkund:in wurde erfolgreich erstellt.": "Einzelkund:in wurde erfolgreich erstellt.",
        "Einzelkund:in erfassen": "Einzelkund:in erfassen",
        "Einzelkund:in bearbeiten": "Einzelkund:in bearbeiten",
        "Allgemeine Informationen": "Allgemeine Informationen",
        "Rechte": "Rechte",
        "Rollen": "Rollen",
        "Rollen auswählen": "Rollen auswählen",
        "Authentifizierung": "Authentifizierung",
        "Passwort (optional)": "Passwort (optional)",
        "Wechseln": "Wechseln",
        "Sprache": "Sprache",
        "Benutzeroberfläche": "Benutzeroberfläche",
        "total": "total",
        "übersetzt": "übersetzt",
        "ausstehend": "ausstehend",
        "Übersetzungsstatistiken werden geladen": "Übersetzungsstatistiken werden geladen",
        "Übersetzungsstatistiken konnten nicht geladen werden.": "Übersetzungsstatistiken konnten nicht geladen werden.",
        "Mögliche Gründe:": "Mögliche Gründe:",
        "Die Story wurde noch nicht ins Weblate übertragen.": "Die Story wurde noch nicht ins Weblate übertragen.",
        "Es gab ein technisches Problem.": "Es gab ein technisches Problem.",
        "Übersetzungsstatistiken": "Übersetzungsstatistiken",
        "Es gibt bereits {count} ähnliche:n Teilnehmer:in. Schalte das Matching aus um den Teilnehmer trotzdem zu erstellen.": "Es gibt bereits {count} ähnliche:n Teilnehmer:in. Schalte das Matching aus um den Teilnehmer trotzdem zu erstellen.",
        "Möchtest du die Variante wirklich duplizieren?": "Möchtest du die Variante wirklich duplizieren?",
        "Preis": "Preis",
        "Produkt erfassen": "Produkt erfassen",
        "Produkt bearbeiten": "Produkt bearbeiten",
        "Produktstatus": "Produktstatus",
        "Produktart (Bexio)": "Produktart (Bexio)",
        "Preis in CHF": "Preis in CHF",
        "MwSt. in Prozent": "MwSt. in Prozent",
        "Produktmedien": "Produktmedien",
        "Produktbild": "Produktbild",
        "Produkte": "Produkte",
        "Produkt wurde erstellt.": "Produkt wurde erstellt.",
        "Produkt wurde aktualisiert.": "Produkt wurde aktualisiert.",
        "Produktgruppen": "Produktgruppen",
        "Produktgruppe erfassen": "Produktgruppe erfassen",
        "Produktgruppe bearbeiten": "Produktgruppe bearbeiten",
        "Es gibt noch keine Einträge.": "Es gibt noch keine Einträge.",
        "Medien": "Medien",
        "Produktgruppenbild": "Produktgruppenbild",
        "Produktgruppe wurde erstellt.": "Produktgruppe wurde erstellt.",
        "Produktgruppe wurde aktualisiert.": "Produktgruppe wurde aktualisiert.",
        "Produktgruppe": "Produktgruppe",
        "Produkte werden im Hintergrund aktualisiert.": "Produkte werden im Hintergrund aktualisiert.",
        "Jetzt aktualisieren": "Jetzt aktualisieren",
        "Die Produktdaten werden aus Bexio regelmässig importiert und mit den bestehenden abgeglichen.": "Die Produktdaten werden aus Bexio regelmässig importiert und mit den bestehenden abgeglichen.",
        "Kürzel (Slug)": "Kürzel (Slug)",
        "Shop wurde erstellt.": "Shop wurde erstellt.",
        "Shop wurde aktualisiert.": "Shop wurde aktualisiert.",
        "Shop wurde gelöscht.": "Shop wurde gelöscht.",
        "Shop erfassen": "Shop erfassen",
        "Shop bearbeiten": "Shop bearbeiten",
        "Shops": "Shops",
        "Aktiv": "Aktiv",
        "Produktgruppen auswählen": "Produktgruppen auswählen",
        "Titel": "Titel",
        "Mit der Registrierung akzeptiere ich die {tos}. Die {privacyPolicy} habe ich gelesen und verstanden.": "Mit der Registrierung akzeptiere ich die {tos}. Die {privacyPolicy} habe ich gelesen und verstanden.",
        "Es muss eine gültige Handynummer angegeben werden.": "Es muss eine gültige Handynummer angegeben werden.",
        "inkl. {taxPercentage}% MwSt.": "inkl. {taxPercentage}% MwSt.",
        "Preis (inkl. {taxPercentage}% MwSt)": "Preis (inkl. {taxPercentage}% MwSt)",
        "Bestelldatum: {orderDate}": "Bestelldatum: {orderDate}",
        "Bestellnummer: {orderReference}": "Bestellnummer: {orderReference}",
        "Kursmodi": "Kursmodi",
        "Kein Datum definiert": "Kein Datum definiert",
        "Anmeldefrist abgelaufen": "Anmeldefrist abgelaufen",
        "Anmeldefrist:": "Anmeldefrist:",
        "Anmeldefrist": "Anmeldefrist",
        "Die Anmeldefrist ist abgelaufen.": "Die Anmeldefrist ist abgelaufen.",
        "Kunde": "Kunde",
        "Link": "Link",
        "eLearning öffnen": "eLearning öffnen",
        "Du hast dich erfolgreich für das Erste Hilfe eLearning von {customer} angemeldet! 🎉": "Du hast dich erfolgreich für das Erste Hilfe eLearning von {customer} angemeldet! 🎉",
        "Es steht dir nun zur Verfügung und du kannst ab sofort mit dem Lernen beginnen.": "Es steht dir nun zur Verfügung und du kannst ab sofort mit dem Lernen beginnen.",
        "Du hast dich für das Erste Hilfe eLearning von {customer} angemeldet, aber noch nicht gestartet. Nutze die Chance, dein Wissen aufzufrischen.": "Du hast dich für das Erste Hilfe eLearning von {customer} angemeldet, aber noch nicht gestartet. Nutze die Chance, dein Wissen aufzufrischen.",
        "Starte jetzt dein Erste Hilfe eLearning! 🚀": "Starte jetzt dein Erste Hilfe eLearning! 🚀",
        "Nachricht konnte nicht erneut zugestellt werden!": "Nachricht konnte nicht erneut zugestellt werden!",
        "Der Kommentar konnte nicht gespeichert werden.": "Der Kommentar konnte nicht gespeichert werden.",
        "{firstname}, dein Erste Hilfe eLearning von {company} wartet auf dich! ⏳": "{firstname}, dein Erste Hilfe eLearning von {company} wartet auf dich! ⏳",
        "Glückwunsch, {firstname}! Dein Erste Hilfe eLearning von {company} ist abgeschlossen 🎓": "Glückwunsch, {firstname}! Dein Erste Hilfe eLearning von {company} ist abgeschlossen 🎓",
        "{firstname}, dein Zugang zum Erste Hilfe eLearning von {company} ist da!": "{firstname}, dein Zugang zum Erste Hilfe eLearning von {company} ist da!",
        "Twint": "Twint",
        "Visa": "Visa",
        "MasterCard": "MasterCard",
        "PostFinance Pay": "PostFinance Pay",
        "Rechnung": "Rechnung",
        "Vorkasse": "Vorkasse",
        "Einzelkund:in wurde erfolgreich aktualisiert.": "Einzelkund:in wurde erfolgreich aktualisiert.",
        "Einzelkund:in wurde erfolgreich gelöscht.": "Einzelkund:in wurde erfolgreich gelöscht.",
        "Möchtest du die Teilnahmebestätigungen herunterladen?": "Möchtest du die Teilnahmebestätigungen herunterladen?",
        "Einzelkunden": "Einzelkunden",
        "Einzelkunden erfassen": "Einzelkunden erfassen",
        "Das eLearning dauert rund {onlineCourseDuration}. Am Ende des eLearnings gibt es eine kurze Lernkontrolle. Es lohnt sich also, die Theorie nicht einfach durchzuklicken!": "Das eLearning dauert rund {onlineCourseDuration}. Am Ende des eLearnings gibt es eine kurze Lernkontrolle. Es lohnt sich also, die Theorie nicht einfach durchzuklicken!",
        "Du hast das Erste Hilfe eLearning von {customer} erfolgreich abgeschlossen – grossartig!": "Du hast das Erste Hilfe eLearning von {customer} erfolgreich abgeschlossen – grossartig!",
        "Danke, dass du dich für Erste Hilfe weitergebildet hast. Bleib sicher!": "Danke, dass du dich für Erste Hilfe weitergebildet hast. Bleib sicher!",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning von {company}! 🚀": "{firstname}, starte jetzt dein Erste Hilfe eLearning von {company}! 🚀",
        "Viel Erfolg! 🚀": "Viel Erfolg! 🚀",
        "Du hast bereits {courseProgress}% vom Erste Hilfe eLearning geschafft – super! 🎉": "Du hast bereits {courseProgress}% vom Erste Hilfe eLearning geschafft – super! 🎉",
        "Bleib dran und lerne weiter, damit du den Kurs erfolgreich abschliessen kannst.": "Bleib dran und lerne weiter, damit du den Kurs erfolgreich abschliessen kannst.",
        "Weiterlernen": "Weiterlernen",
        "Keine Kursleitung definiert": "Keine Kursleitung definiert",
        "Wir freuen uns, dir deine Teilnahmebestätigung für diesen Onlinekurs zu übermitteln. Die Datei findest du im Anhang dieser E-Mail.": "Wir freuen uns, dir deine Teilnahmebestätigung für diesen Onlinekurs zu übermitteln. Die Datei findest du im Anhang dieser E-Mail.",
        "Hat die Lernkontrolle im eLearning bestanden.": "Hat die Lernkontrolle im eLearning bestanden.",
        "hat am <strong>{dateText}</strong> am {durationText} {courseTypeName} nach SRC Richtlinien 2021 teilgenommen.": "hat am <strong>{dateText}</strong> am {durationText} {courseTypeName} nach SRC Richtlinien 2021 teilgenommen.",
        "hat am <strong>{dateText}</strong> das {durationText} {courseTypeName} nach SRC Richtlinien 2021 erfolgreich abgeschlossen.": "hat am <strong>{dateText}</strong> das {durationText} {courseTypeName} nach SRC Richtlinien 2021 erfolgreich abgeschlossen.",
        "{minutes}-minütige": "{minutes}-minütige",
        "{hours}-stündige": "{hours}-stündige",
        "{hours}-stündigen": "{hours}-stündigen",
        "Teilnehmerliste importieren": "Teilnehmerliste importieren",
        "Teilnehmer:innen wurden {statusHint} aus Datei {fileName} in Kurs {course} importiert.": "Teilnehmer:innen wurden {statusHint} aus Datei {fileName} in Kurs {course} importiert.",
        "Sortierung": "Sortierung",
        "Kategorie-Label": "Kategorie-Label",
        "Die Teilnehmer:innen aus der Datei \"{fileName}\"\" wurden {statusHint} im Kurs {course} erfasst.": "Die Teilnehmer:innen aus der Datei \"{fileName}\"\" wurden {statusHint} im Kurs {course} erfasst.",
        "Es wurden total:": "Es wurden total:",
        "<strong>{count}</strong> neu angelegt": "<strong>{count}</strong> neu angelegt",
        "<strong>{count}</strong> aktualisiert": "<strong>{count}</strong> aktualisiert",
        "<strong>{count}</strong> am Kurs angemeldet": "<strong>{count}</strong> am Kurs angemeldet",
        "Die Teilnehmer:innen haben eine Anmeldebestätigung erhalten.": "Die Teilnehmer:innen haben eine Anmeldebestätigung erhalten.",
        "Folgende Zeilen konnten aufgrund ungültiger Daten (<span class=\"text-red\">rot markiert</span>) nicht importiert werden:": "Folgende Zeilen konnten aufgrund ungültiger Daten (<span class=\"text-red\">rot markiert</span>) nicht importiert werden:",
        "messages": {
            "general": {
                "duration": {
                    "days": "ein Tag|{count} Tage",
                    "months": "ein Monat|{count} Monate",
                    "years": "ein Jahr|{count} Jahre"
                }
            },
            "feedback": {
                "moods": {
                    "positive": "Positiv",
                    "neutral": "Neutral",
                    "negative": "Negativ"
                }
            },
            "communication": {
                "notifications": {
                    "channel": {
                        "mail": "E-Mail",
                        "database": "Datenbank",
                        "twilio": "SMS"
                    },
                    "status": {
                        "sent": "Gesendet",
                        "sending": "Wird versendet...",
                        "skipped": "Übersprungen",
                        "failed": "Fehlgeschlagen"
                    }
                }
            },
            "elearning": {
                "stories": {
                    "status": {
                        "draft": "In Arbeit",
                        "review": "Review",
                        "translation": "In Übersetzung",
                        "done": "Fertig"
                    },
                    "subject_focus_list": {
                        "adults": "Erwachsene",
                        "teenagers": "Jugendliche (10-15)",
                        "children": "Kinder (4-9)",
                        "babies": "Babys (0-3)"
                    }
                }
            },
            "shop": {
                "products": {
                    "type": {
                        "service": "Dienstleistung",
                        "goods": "Ware"
                    },
                    "status": {
                        "active": "Aktiv",
                        "inactive": "Inaktiv",
                        "archived": "Archiviert"
                    }
                }
            }
        }
    },
    "en": {
        "Anmelden": "Log in",
        "E-Mail": "Email",
        "Passwort": "Password",
        "Passwort vergessen?": "Forgot your password?",
        "Eine Woche lang angemeldet bleiben": "Stay signed in for a week",
        "Willkommen bei": "Welcome to",
        "Passwort zurücksetzen": "Reset password",
        "Zurück zur Anmeldung": "Return to sign in",
        "Link anfordern": "Request link",
        "zurück": "back",
        "Liste exportieren": "Export list",
        "Anmeldungen": "Registrations",
        "Abmeldungen": "Cancellations",
        "Vorname": "First name",
        "Nachname": "Last name",
        "Kurssprache": "Course language",
        "Beschreibung": "Description",
        "Link ist aktiv": "Link is active",
        "Kurs freigeben": "Share course",
        "Es existieren keine Daten.": "No existing data.",
        "Willkommen": "Welcome",
        "Teilnehmende": "Participants",
        "Sortieren nach": "Sort by",
        "Datum": "Date",
        "Adresse": "Address",
        "Plätze frei": "Remaining slots",
        "ausgebucht": "fully booked",
        "Weitere Person anmelden": "Register additional person",
        "An-/Abmeldung": "Registration/cancellation",
        "Ja, ich nehme teil": "Yes, I will participate",
        "Nein, ich bin verhindert": "No, I'm unable to attend",
        "Ich akzeptiere die": "I accept the",
        "allgemeinen Geschäftsbedingungen": "Terms and Conditions",
        "Die": "The",
        "Datenschutzerklärung": "Privacy policy",
        "habe ich gelesen und verstanden.": "I have read and understood.",
        "Absenden": "Send",
        "Fragen oder Probleme?": "Questions or problems?",
        "Geburtsdatum": "Date of birth",
        "Geburtsdatum (TT.MM.JJJJ)": "Date of birth (dd.mm.yyyy)",
        "Hallo": "Hello",
        "Anmeldebestätigung": "Registration Confirmation",
        "Erste Hilfe Kurs": "First Aid Course",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Thank you for your registration for the First Aid Course. Here are the most important details again",
        "Liebe Grüsse": "Best regards",
        "Dein Sanio-Team": "Your Sanio Team",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Booking confirmation",
        "Terminerinnerung": "Appointment reminder",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "The time has come, and the first aid course is taking place soon. Here are the most important details again:",
        "Zurück zum Login": "Back to login",
        "Passwort bestätigen": "Confirm password",
        "Mitarbeiter:innen": "Employees",
        "Deutsch": "German",
        "Französisch": "French",
        "Italienisch": "Italian",
        "Englisch": "English",
        "Spanisch": "Spanish",
        "Portugiesisch": "Portuguese",
        "Kurse": "Courses",
        "Impersonation beenden": "Stop impersonation",
        "INSURANCE_FLAT": "Administration fee",
        "lessons": "Driving lessons",
        "Teachers": "Driving instructors",
        "In Kurs verschieben": "Change course",
        "Abbrechen": "Cancel",
        "Abmelden": "Log out",
        "Kursleiterinnen": "Instructors",
        "absences": "Absences",
        "All": "All",
        "app": "Theory app",
        "certificates.not-yet-known": "Not yet known",
        "certificates.sanio": "Course confirmation Sanio",
        "certificates.src": "SRC certificate",
        "comments": "Comments",
        "course_documents": "Course documents",
        "course_manuals": "Course instructions",
        "Die Datei {fileName} wurde {statusHint} importiert.": "The file {fileName} was imported {statusHint}.",
        "employment": "Employment",
        "erfolgreich": "Successful",
        "exams": "Examinations",
        "Fehlermeldung": "Error message",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "The following lines could not be imported due to invalid (<span class=\"text-red\">marked in red</span>) data:",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "The following lines were nevertheless imported due to unclear assignment.",
        "Handynummer": "Cell phone number",
        "Instructors": "Instructors",
        "insurance": "Insurance",
        "Kontaktdaten": "Contact details",
        "locations": "Locations",
        "Mitarbeitende": "Employees",
        "Spalte": "Column",
        "students": "Pupils",
        "Teilnehmerübersicht öffnen": "Open participant overview",
        "teilweise": "Partly",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The {attribute} has already appeared in a data leak. Please choose another {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} must consist of at least one character.",
        "The {attribute} must contain at least one number.": "{attribute} must consist of at least one number.",
        "The {attribute} must contain at least one symbol.": "{attribute} must consist of at least one special character.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} must consist of at least one uppercase and one lowercase letter.",
        "vku": "VKU courses",
        "voice_status_end": "Finished",
        "voice_status_missed": "Missed",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_queue": "Queue",
        "voice_status_start": "Start",
        "voice_status_taken": "in conversation",
        "voice_status_voicemail": "Voice message",
        "Zeile": "Line",
        "Ähnliche TN": "Similar TN",
        "Email": "Email",
        "voice_status_initiated": "Welcome",
        "Dashboard": "Dashboard",
        "Mehr anzeigen": "Show more",
        "{participantName} in Kurs {courseName} verschieben?": "Move {participantName} to course {courseName}?",
        "Zum Kurs": "To the course",
        "Umbuchungsbestätigung senden": "Send rebooking confirmation",
        "Zurück": "Back",
        "Verschieben": "Move",
        "Willkommen {name} 👋": "Welcome {name} 👋",
        "Kursübersicht": "Course overview",
        "In welchen Kurs soll {participantName} verschoben werden?": "Which course should {participantName} be moved to?",
        "Diese Anmeldemaske wurde deaktiviert.": "This login screen has been deactivated.",
        "errors.4xx.title": "Unexpected error",
        "errors.4xx.head": "Oops...",
        "errors.5xx.head": "Ou ou...",
        "errors.4xx.hint": "An error has occurred. Your request could not be processed. Please check your entries or try again later. The error has been reported to us.",
        "errors.5xx.title": "Internal server error",
        "errors.5xx.hint": "An unexpected error has occurred. Go back to the home page or try again later. The error has been reported to us.",
        "errors.401.title": "Access denied",
        "errors.401.head": "Oops…",
        "errors.401.hint": "You are not authorized to access this page. Please log in and try again.",
        "errors.403.title": "Access denied",
        "errors.403.head": "Oops…",
        "errors.403.hint": "Access denied. You do not have permission to visit this page. Please contact us if you believe this is an error.",
        "errors.404.title": "Page not found",
        "errors.404.head": "Oops...",
        "errors.404.hint": "The page you have called up does not exist. Go back or click on the button to return to the start page.",
        "errors.500.title": "Internal server error",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "An unexpected error has occurred. Go back to the home page or try again later. The error has been reported to us.",
        "errors.503.title": "Maintenance mode",
        "errors.503.head": "One moment please...",
        "errors.503.hint": "We are currently performing maintenance to make improvements and fix bugs for a better user experience. Please try again shortly. Thank you for your understanding.",
        "errors.503.hint2": "In urgent cases, you can reach us by e-mail at <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> or by telephone at <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Timeout",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "The request took too long. Please try again later. The error has been reported to us.",
        "errors.back-to-start": "To the homepage",
        "{attribute} is keine gültige Sprache.": "{attribute} is not a valid language.",
        "Fehler beim Laden der Kurse": "Error loading the courses",
        "oder": "or",
        "Zum Kalender hinzufügen": "Add to calendar",
        "Der Code ist ungültig.": "The code is invalid.",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "You have been rebooked into the course \"{courseName}\".",
        "Die aktuellen Infos lauten:": "Here is the new information:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "If you have any questions, please contact {name} directly:",
        "Kursort:": "Course location:",
        "Kursdaten": "Course dates",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Soon the time will come and the course \"{courseName}\" will take place.",
        "Hier nochmals die wichtigsten Infos:": "Here is the most important information again:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "You have been successfully enrolled on the course \"{courseName}\" 🎉",
        "Kurs-ID": "Course ID",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "No e-mail address or cell phone number was provided.",
        "Die E-Mail-Adresse ist ungültig.": "The e-mail address is invalid",
        "Die Handynummer ist ungültig.": "The cell phone number is invalid.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Unfortunately, you cannot register with this e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Unfortunately, you cannot register with this telephone number.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Thank you for your registration for the First Aid Course. Here are the most important details again:",
        "Text wurde in die Zwischenablage kopiert": "Text has been copied to the clipboard",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Text could not be copied to the clipboard",
        "Der Katalog wurde erstellt.": "The catalog was created.",
        "Der Katalog wurde aktualisiert.": "The catalog has been updated.",
        "Der Katalog wurde entfernt.": "The catalog has been removed.",
        "Das Thema wurde erstellt.": "The topic was created.",
        "Das Thema wurde aktualisiert.": "The topic has been updated.",
        "Das Thema wurde entfernt.": "The topic has been removed.",
        "Der Kurs wurde dupliziert 🎉": "The course has been duplicated 🎉",
        "Kurs": "Course",
        "Story wurde erfolgreich kopiert 🎉": "Story was successfully copied 🎉",
        "Eine Variante|{count} Varianten": "One variant|{count} Variants",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "The eLearning area is not available for this customer.",
        "Geschlecht": "Gender",
        "Männlich": "male",
        "Keinem Kurs zugeordnet": "Not assigned to a course",
        "Suchbegriff": "Search term",
        "Weiblich": "female",
        "Divers": "other",
        "Projektstandort": "Project location",
        "Wohnkanton": "Canton of residence",
        "Sanio Logo": "Sanio logo",
        "hier": "here",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Click here for our <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">general terms and conditions</a> and our <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">privacy policy</a>.",
        "Hallo {name} 👋": "Hello {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Here are the most important details again:",
        "Teilnehmer:innen": "Participants",
        "Name": "Name",
        "Letzter Kurs": "Last course",
        "Nächster Kurs": "Next course",
        "Hinzufügen": "Add",
        "Exportieren": "Export",
        "Dein Sanio Team": "Your Sanio Team",
        "Alle": "All",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "If you no longer wish to receive emails from this list, you can unsubscribe at <a href=\"{unsubscribeListUrl}\" target=\"_blank\">here</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Do you no longer wish to receive offers from us? Then you can unsubscribe at <a href=\"{unsubscribeUrl}\" target=\"_blank\">here</a>.",
        "Ungruppierte Kurse": "Ungrouped courses",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Several accounts were found for these credentials. Please select the desired customer.",
        "Anmeldebestätigung erneut zustellen": "Resend registration confirmation",
        "Einladung zum eLearning": "Invitation to eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "We are pleased to inform you that the eLearning is now available for you! You can start learning now. 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "You must complete the eLearning by the time of the <strong>{courseName}</strong> on <strong>{courseStart}</strong> at <strong>{coursePlace}</strong>. It serves as the foundation for the practical course.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "The eLearning takes about 2 hours in total.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "You can complete the eLearning either in one go or in several stages.",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "There is a short learning check at the end. It is therefore worth working through the content carefully!",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Participant:in unsubscribe / remove from course",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Do you really want to deregister / remove the participant from the course?",
        "Abmelden / entfernen": "Log out / remove",
        "eLearning Einladung verschicken": "Send eLearning invitation",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Do you really want to send an eLearning invitation?",
        "Einladung verschicken": "Send invitation",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Do you really want to resend the registration confirmation?",
        "Bestätigung erneut verschicken": "Send confirmation again",
        "Teilnehmer:in abmelden": "Unsubscribe Participant:in",
        "Bemerkungen": "Remarks",
        "Wert fehlt!": "missing value!",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} No participants were created and {totalUpdated} was updated.|{1} One participant was created and {totalUpdated} was updated.|[2,*] {count} participants were created and {totalUpdated} was updated.",
        "Gruppe bearbeiten": "Edit group",
        "Neue:n Teilnehmer:in anmelden": "Register new participants",
        "Zu Kursgruppe hinzufügen": "Add course to group",
        "Neue Kursgruppe erstellen": "Create new course group",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Do you really want to delete the course permanently? This action cannot be undone.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Do you really want to archive the course? The course is then no longer visible, but can be restored by an admin.",
        "Freigabelink löschen": "Delete share link",
        "Möchtest du den Freigabelink wirklich löschen?": "Do you really want to delete the share link?",
        "Archivieren": "Archive",
        "Endgültig löschen": "Delete permanently",
        "Gruppe löschen": "Delete group",
        "Möchtest du diese Gruppe wirklich löschen?": "Do you really want to delete this group?",
        "Kurs aus Gruppe entfernen": "Remove course from group",
        "Kurs duplizieren": "Duplicate course",
        "Kurs archivieren": "Archive course",
        "Kurs löschen": "Delete course",
        "Der Link wurde in die Zwischenablage kopiert! 🎉": "The link has been copied to the clipboard! 🎉",
        "Der Freigabelink wurde gelöscht! 🎉": "The share link has been deleted! 🎉",
        "Link teilen": "Share link",
        "nimmt teil": "participates",
        "Ähnliche Teilnehmer:innen": "Similar participants",
        "Ein unerwarteter Fehler ist aufgetreten": "An unexpected error has occurred",
        "Bereits in diesem Kurs vorhanden": "Already available in this course",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "No similar participants were found.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Fill out the form to start the search.",
        "Suche ähnliche Teilnehmer:innen...": "Looking for similar participants...",
        "eLearning nicht gefunden": "eLearning not found",
        "Wie hat dir das eLearning allgemein gefallen?": "How did you like the eLearning in general?",
        "🤩 Sehr gut!": "🤩 Very good!",
        "😊 Gut": "😊 Good",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Not so good",
        "😩 Schlecht": "😩 Bad",
        "Bitte wähle eine Antwort aus.": "Please select an answer.",
        "Wie verständlich waren für dich die Lerninhalte?": "How comprehensible was the learning content for you?",
        "🤩 Sehr verständlich!": "🤩 Very understandable!",
        "😊 Verständlich": "😊 Understandable",
        "😕 Nicht so verständlich": "😕 Not so understandable",
        "😩 Gar nicht verständlich": "😩 Not understandable at all",
        "🤩 Sehr einfach!": "🤩 Very simple!",
        "😊 einfach": "😊 simple",
        "😐 Nicht so einfach": "😐 Not that simple",
        "😕 Schwierig": "😕 Difficult",
        "😩 Sehr schwierig": "😩 Very difficult",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "How would you rate the user-friendliness of eLearning?",
        "🤩 Super einfache Bedienung!": "🤩 Super simple operation!",
        "😊 Einfach zu bedienen": "😊 Easy to operate",
        "😐 Teilweise etwas mühsam": "😐 Sometimes a bit tedious",
        "😩 Richtig mühsame Bedienung": "😩 Really tedious operation",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "How difficult was the learning check at the end for you?",
        "Warum war die Lernkontrolle für dich einfach?": "Why was the learning check easy for you?",
        "Überspringen": "Skip",
        "Warum war die Lernkontrolle für dich schwierig?": "Why was the learning check difficult for you?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "How can we improve eLearning? What did you miss?",
        "Würdest du das eLearning weiterempfehlen?": "Would you recommend eLearning to others?",
        "🤩 Ja klar!": "🤩 Yes, of course!",
        "😊 Ja, wahrscheinlich schon": "😊 Yes, probably",
        "😐 Weiss nicht recht": "😐 Don't really know",
        "😕 Eher nicht": "😕 Rather not",
        "😩 Nein, auf keinen Fall": "😩 No, definitely not",
        "Vielen Dank für deine Antworten!": "Thank you very much for your answers!",
        "Cool hast du dir die Zeit genommen!": "Cool that you took the time!",
        "Fehler beim Laden der Suchergebnisse": "Error loading the search results",
        "Speichern": "Save",
        "Jetzt mit dem eLearning starten": "Start eLearning now",
        "Zu Kurs zugeordnet": "Assigned to course",
        "Teilnehmer:innen {company}": "Participants {company}",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, don't forget the eLearning for the course from {company}!",
        "Dein my Sanio Login Code": "Your my Sanio login code",
        "Dein my.sanio Verifizierungscode": "Your my.sanio verification code",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "I accept the {tos} and have read and understood the {privacyPolicy}.",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "The terms and conditions and the privacy policy must be accepted.",
        "Der Kurs ist leider bereits ausgebucht.": "The course is unfortunately already fully booked.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Unfortunately we were unable to register you for the course.",
        "Von:": "From:",
        "An:": "To:",
        "CC:": "CC:",
        "BCC:": "BCC:",
        "Antwort an:": "Reply to:",
        "Anhänge": "Attachments",
        "unbekannter Betreff": "unknown subject",
        "Status": "Status",
        "Kanal": "Channel",
        "Inhalt": "Contents",
        "Persönliche Daten": "Personal data",
        "Zukünftige Kurse": "Future courses",
        "Vergangene Kurse": "Past courses",
        "eLearning": "eLearning",
        "Fortschritt:": "Progress:",
        "Dauer:": "Duration:",
        "Kommunikation": "Communication",
        "Zeit": "Time",
        "Profil": "Profile",
        "Nicht dargestellte Kursdaten": "Not displayed course data",
        "Benutzerbild": "User image",
        "Meine Kurse": "My courses",
        "Dashboard Instruktor:innen": "Instructor dashboard",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "If we found an account for this email address, you will receive an email shortly with a login link (Please also check your spam folder).",
        "Jetzt einloggen": "Log in now",
        "Link zum Einloggen": "Login link",
        "Teilnahme-Status erfolgreich aktualisiert.": "Participation status successfully updated.",
        "Team": "Team",
        "Anwesenheit": "Presence",
        "Gemäss Kursleiter:in anwesend": "Present according to instructor",
        "Gemäss Kursleiter:in abwesend": "Absent according to instructor",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 You have no courses this week.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 You have no upcoming courses.",
        "😌 Du hast keine vergangenen Kurse.": "😌 You have no past courses.",
        "Diese Woche": "This week",
        "und": "and",
        "Geschäftsführerin Sanio AG": "CEO Sanio AG",
        "Zertifikat": "Certificate",
        "Teilnahmebestätigung": "Certificate of Course Completion",
        "Kursleitung:": "Instructed by:",
        "Dieses Zertifikat ist bis am {date} gültig.": "This certificate is valid until {date}.",
        "Eine Auffrischung der Erste Hilfe Grundlagen alle 2 Jahre wird empfohlen.": "A refresher course in first aid basics is recommended every 2 years.",
        "Download wird gestartet...": "Download is started...",
        "Download erfolgreich abgeschlossen.": "Download successfully completed.",
        "Download fehlgeschlagen.": "Download failed.",
        "Generiere Teilnahmebestätigungen...": "Generate confirmations of participation...",
        "Teilnahmebestätigungen wurden erfolgreich generiert und heruntergeladen.": "Confirmations of participation have been successfully generated and downloaded.",
        "Fehler beim Erstellen der Teilnahmebestätigungen.": "Error when creating the confirmation of participation.",
        "Generiere Teilnahmebestätigung...": "Generate confirmation of participation...",
        "Teilnahmebestätigung wurde erfolgreich generiert und heruntergeladen.": "Confirmation of participation was successfully generated and downloaded.",
        "Fehler beim Erstellen der Teilnahmebestätigung.": "Error when creating the confirmation of participation.",
        "Teilnehmerliste exportieren": "Export participant list",
        "Teilnahmebestätigungen als PDF": "Confirmation of participation as PDF",
        "Teilnahmebestätigungen als ZIP": "Confirmation of participation as ZIP",
        "wurde erstellt": "was created",
        "kommentierte": "commented",
        "Schreibe deinen Kommentar...": "Write your comment...",
        "Zurücksetzen": "Reset",
        "Kommentieren": "Comment",
        "Kommentar bearbeiten": "Edit comment",
        "Bearbeiten": "Edit",
        "Löschen": "Delete",
        "Aktivität": "Activity",
        "Teilnehmer:in hinzufügen": "Add participant",
        "Teilnahmebestätigung herunterladen": "Download confirmation of participation",
        "Teilnehmer abgleichen": "Synchronize participants",
        "Dieses Objekt": "This object",
        "Der Kurs ist ausgebucht.": "The course is fully booked.",
        "abgeschlossen": "completed",
        "Der Kurs ist bereits beendet.": "The course has already ended.",
        "{capacity} Teilnehmende": "{capacity} Participants",
        "{availableSpots} Plätze frei": "{availableSpots} Seats free",
        "Es sind keine anderen Kurse verfügbar.": "There are no other courses available.",
        "Teilnehmer wurde in neuen Kurs verschoben.": "Participant was moved to a new course.",
        "Beendete Kurse können nicht mehr bearbeitet werden.": "Completed courses can no longer be edited.",
        "certificates.none": "No certificate",
        "Der Benutzer wurde erfolgreich erstellt.": "The user has been successfully created.",
        "Teilnehmer:in anzeigen": "Show participant",
        "Nachrichtenverlauf": "Message history",
        "Kurs erstellen": "Create course",
        "Kurstypen": "Course types",
        "Keine Daten gefunden.": "No data found.",
        "Kurstyp bearbeiten": "Edit course type",
        "Kurs bearbeiten": "Edit course",
        "Die Offerte wurde erstellt.": "The offer has been prepared.",
        "Das Angebot wurde aktualisiert.": "The offer has been updated.",
        "Das Angebot wurde entfernt.": "The offer has been removed.",
        "Teilnehmer erfolgreich gespeichert!": "Participant successfully saved!",
        "Teilnehmer wurde gelöscht!": "Participant has been deleted!",
        "Nachricht wurde erneut zugestellt 🎉": "Message has been delivered again 🎉",
        "Kursgruppe erfolgreich erstellt 🎉": "Course group successfully created 🎉",
        "Kursgruppe erfolgreich aktualisiert 🎉": "Course group successfully updated 🎉",
        "Kursgruppe wurde gelöscht!": "Course group has been deleted!",
        "Geteilter Link gelöscht!": "Shared link deleted!",
        "Teilnehmer wurde in den neuen Kurs verschoben.": "Participant was moved to the new course.",
        "Der Kurs wurde erfolgreich gelöscht!": "The course has been successfully deleted!",
        "Der Kurs wurde erfolgreich archiviert!": "The course has been successfully archived!",
        "Teilnehmer:in erfolgreich abgemeldet.": "Participant successfully deregistered.",
        "Der Benutzer wurde erfolgreich aktualisiert.": "The user has been successfully updated.",
        "Der Benutzer wurde erfolgreich gelöscht.": "The user has been successfully deleted.",
        "Die Weiterleitung wurde erstellt!": "The course has been successfully archived!",
        "Die Weiterleitung wurde aktualisiert!": "The forwarding has been updated!",
        "Die Weiterleitung wurde gelöscht!": "The forwarding has been deleted!",
        "Mail Statusinformationen wurden aktualisiert.": "Mail status information has been updated.",
        "Der Kontakt wurde aktualisiert.": "The contact has been updated.",
        "Kurse wurden der Gruppe hinzugefügt.": "Courses have been added to the group.",
        "Auswählen": "Select",
        "Kurs hat noch nicht begonnen.": "Course has not yet started.",
        "HINWEIS:": "NOTE:",
        "Das Login wurde gelöscht! 🎉": "The login has been deleted! 🎉",
        "Der Kundenbereich wurde gespeichert! 🎉": "The customer area has been saved! 🎉",
        "Nachricht wurde verschickt 🎉": "Message has been sent 🎉",
        "Der Kurs wurde gespeichert": "The course has been saved",
        "Das Unternehmen wurde aktualisiert.": "The company has been updated.",
        "Das Unternehmen wurde gelöscht!": "The company has been deleted!",
        "Kurstyp wurde erfolgreich aktualisiert.": "Course type has been successfully updated.",
        "vollständig": "complete",
        "Der Import wurde gestartet und wird im Hintergrund ausgeführt.": "The import has been started and is running in the background.",
        "Möchtest du das Element wirklich löschen?": "Do you really want to delete the element?",
        "Das Login wurde erstellt! 🎉": "The login has been created! 🎉",
        "Der Kurs wurde gespeichert 🎉": "The course has been saved 🎉",
        "Das Unternehmen wurde erstellt.": "The company was created.",
        "Der Kontakt wurde entfernt.": "The contact has been removed.",
        "Der Kundenbereich wurde eröffnet! 🎉": "The customer area has been opened! 🎉",
        "Der Kommentar wurde gespeichert.": "The comment has been saved.",
        "Der Kommentar wurde aktualisiert.": "The comment has been updated.",
        "Der Kommentar wurde entfernt.": "The comment has been removed.",
        "Der Kontakt wurde erstellt.": "The contact was created.",
        "Link für gesamte Kursliste kopieren": "Copy link for complete course list",
        "Link für diese Kursgruppe kopieren": "Copy link for this course group",
        "Willkommen im": "Welcome to the",
        "Instruktor:innen Bereich": "Instructors area",
        "Der Login-Link ist ungültig oder abgelaufen.": "The login link is invalid or expired.",
        "weiter": "continue",
        "+{more} weitere": "+{more} more",
        "Telefonzeiten": "Telephone hours",
        "Montag bis Freitag": "Monday to Friday",
        "9.00 bis 12.00 Uhr": "9.00 to 12.00",
        "13.00 bis 16.00 Uhr": "13.00 to 16.00",
        "Notfallnummer (für Kurse)": "Emergency number (for courses)",
        "Link für diesen Kurs kopieren": "Copy link for this course",
        "Link für Anmeldung teilen": "Share link for registration",
        "🤔 Es sind keine Kurse geplant.": "🤔 No courses are planned.",
        "Suchen...": "Search...",
        "Gymnasium": "High school",
        "Vereine": "Clubs",
        "Sonstiges": "Miscellaneous",
        "Teilnahmebestätigung Erste Hilfe Kurs": "Confirmation of participation in first aid course",
        "Zertifikat BLS AED SRC Kurs": "BLS AED SRC course certificate",
        "Kursinstruktor:in": "Course instructor",
        "Management": "Management",
        "Büro": "Office",
        "Schule": "School",
        "Berufsschule": "Vocational school",
        "Kita": "Daycare center",
        "Betrieb": "Company",
        "Die Datei findest du im Anhang dieser E-Mail.": "You will find the file attached to this e-mail.",
        "Herzlichen Glückwunsch und weiterhin viel Erfolg!": "Congratulations and continued success!",
        "Wir freuen uns, dir dein Zertifikat für den <strong>{courseName}</strong> zu übermitteln.": "We are pleased to send you your certificate for the <strong>{courseName}</strong>.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für den <strong>{courseName}</strong> zu übermitteln.": "We are pleased to send you your confirmation of participation for the <strong>{courseName}</strong>.",
        "Zertifikate werden nun generiert und an die Teilnehmer:innen verschickt 🎉": "Certificates are now generated and sent to the participants 🎉",
        "Zertifikat wird nun generiert und an den/die Teilnehmer:in verschickt 🎉": "Certificate is now generated and sent to the participant 🎉",
        "Teilnahmebestätigungen an Teilnehmer versenden": "Sending confirmations of participation to participants",
        "Teilnahmebestätigungen per Mail zustellen": "Send confirmation of participation by e-mail",
        "Möchtest du die Teilnahmebestätigungen an alle Teilnehmer:innen zustellen?": "Would you like to send the confirmation of participation to all participants?",
        "ACHTUNG:": "ATTENTION:",
        "Für einige Teilnehmer:innen, die Anspruch auf eine Teilnahmebestätigung haben, liegt keine E-Mail-Adresse vor. Daher kann die Bestätigung an die folgenden Personen nicht per Mail zugestellt werden:": "For some participants who are entitled to a confirmation of participation, no e-mail address is available. Therefore, the confirmation cannot be sent to the following persons by e-mail:",
        "Bestätigung zustellen": "Send confirmation",
        "Teilnahmebestätigung per Mail versenden": "Send confirmation of participation by e-mail",
        "Willst du wirklich die Teilnahmebestätigung per Mail versenden?": "Do you really want to send the confirmation of participation by e-mail?",
        "Füge deine E-Mail-Adresse ein, um eine Anmeldebestätigung und ein Erinnerungsmail zu erhalten.": "Enter your e-mail address to receive a registration confirmation and a reminder e-mail.",
        "Sanio Kundenbereich": "Sanio customer area",
        "Du bekommst von uns einen Link per E-Mail zugesendet, um dein Passwort zurückzusetzen. Bitte gib deine E-Mail-Adresse ein, die du zum Login im Sanio Kundenportal verwendest.": "We will send you a link by e-mail to reset your password. Please enter the e-mail address you use to log in to the Sanio customer portal.",
        "Liebe Geschäftskund:innen, unser Portal für die Kursverwaltung hat einen neuen Namen. Das {portalName} erreicht ihr ab sofort unter {sanioPortalLink}.": "Dear business customers, our portal for course management has a new name. You can now reach the {portalName} at {sanioPortalLink}.",
        "Teilnahmebestätigungen herunterladen": "Download confirmations of participation",
        "Hier dein persönlicher Zugang, um dich im my.sanio einzuloggen:": "Here is your personal access to log in to my.sanio:",
        "Wichtige Hinweise:": "Important notes:",
        "Der Link kann nur einmal verwendet werden.": "The link can only be used once.",
        "Er ist zeitlich begrenzt gültig.": "It is valid for a limited period of time.",
        "Falls das Login nicht funktioniert, kannst du jederzeit einen neuen Link auf der <a href=\"{loginPageUrl}\">Anmeldeseite</a> anfordern.": "If the login does not work, you can request a new link on the <a href=\"{loginPageUrl}\">login page</a> at any time.",
        "Hier dein persönlicher Zugang, um dich im Sanio Kundenportal einzuloggen:": "Here is your personal access to log in to the Sanio customer portal:",
        "Hier dein persönlicher Zugang, um dich im Sanio Instruktor:innen Bereich einzuloggen:": "Here is your personal access to log in to the Sanio Instructor area:",
        "in Planung": "in planning",
        "Füge eine persönliche E-Mail-Adresse ein, damit der:die Teilnehmer:in alle kursbezogenen Mitteilungen (z. B. Anmeldebestätigung, Erinnerung, Teilnahmebestätigung) direkt per Mail erhält.": "Enter a personal e-mail address so that the participant receives all course-related messages (e.g. registration confirmation, reminder, confirmation of participation) directly by e-mail.",
        "vom <strong>{dateStart}</strong> bis <strong>{dateEnd}</strong>": "from <strong>{dateStart}</strong> to <strong>{dateEnd}</strong>",
        "am <strong>{date}</strong>": "on <strong>{date}</strong>",
        "am <strong>{dateStart}</strong> und <strong>{dateEnd}</strong>": "at <strong>{dateStart}</strong> and <strong>{dateEnd}</strong>",
        "Dein Verifizierungscode lautet: <strong>{code}</strong>": "Your verification code is: <strong>{code}</strong>",
        "Dein Sanio-Verifizierungscode": "Your Sanio verification code",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im Sanio Kundenportal mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "If a password is stored, the contact can log in to the Sanio customer portal with their e-mail and password. Otherwise, a login link will be sent.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im team.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "If a password is stored, the contact can log in to team.sanio with their e-mail and password. Otherwise a login link will be sent.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im my.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "If a password is stored, the contact can log in to my.sanio with their e-mail and password. Otherwise a login link will be sent.",
        "Einzelkund:in wurde erfolgreich erstellt.": "Individual customer was successfully created.",
        "Einzelkund:in erfassen": "Create individual customer",
        "Einzelkund:in bearbeiten": "Edit individual customer",
        "Allgemeine Informationen": "General information",
        "Rechte": "Permissions",
        "Rollen": "Roles",
        "Rollen auswählen": "Select roles",
        "Authentifizierung": "Authentication",
        "Passwort (optional)": "Password (optional)",
        "Wechseln": "Change",
        "Benutzeroberfläche": "User interface",
        "Sprache": "Language",
        "total": "total",
        "übersetzt": "translated",
        "ausstehend": "pending",
        "Übersetzungsstatistiken werden geladen": "Translation statistics are being loaded",
        "Übersetzungsstatistiken konnten nicht geladen werden.": "Translation statistics could not be loaded.",
        "Mögliche Gründe:": "Possible reasons:",
        "Die Story wurde noch nicht ins Weblate übertragen.": "The story has not yet been transferred to Weblate.",
        "Es gab ein technisches Problem.": "There was a technical problem.",
        "Übersetzungsstatistiken": "Translation statistics",
        "Du musst die AGB akzeptieren!": "You must accept the terms and conditions!",
        "Falls du Excel für den Import verwendest, achte darauf, dass Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "If you use Excel for the import, make sure that columns such as the date of birth are formatted as text.",
        "Du musst die AGB akzeptieren, um fortfahren zu können.": "You must accept the terms and conditions in order to continue.",
        "Bitte gib eine gültige Handynummer ein.": "Please enter a valid cell phone number.",
        "Es gibt bereits {count} ähnliche:n Teilnehmer:in. Schalte das Matching aus um den Teilnehmer trotzdem zu erstellen.": "There are already {count} similar participants. Switch off matching to create the participant anyway.",
        "Möchtest du die Variante wirklich duplizieren?": "Do you really want to duplicate the variant?",
        "Vielen Dank! Wir haben deine Angaben erhalten.": "Thank you very much! We have received your details.",
        "Deine Ansprechperson:": "Your contact person:",
        "Deine Ansprechpersonen:": "Your contact persons:",
        "Preis": "Price",
        "Produkt erfassen": "Enter product",
        "Produkt bearbeiten": "Edit product",
        "Produktstatus": "Product status",
        "Preis in CHF": "Price in CHF",
        "MwSt. in Prozent": "VAT in percent",
        "Produktmedien": "Product media",
        "Produktbild": "Product image",
        "Produkte": "Products",
        "Produkt wurde erstellt.": "Product was created.",
        "Produkt wurde aktualisiert.": "Product has been updated.",
        "Produktgruppen": "Product groups",
        "Produktgruppe erfassen": "Enter product group",
        "Produktgruppe bearbeiten": "Edit product group",
        "Es gibt noch keine Einträge.": "There are no entries yet.",
        "Medien": "Media",
        "Produktgruppenbild": "Product group picture",
        "Produktgruppe wurde erstellt.": "Product group was created.",
        "Produktgruppe wurde aktualisiert.": "Product group has been updated.",
        "Produktgruppe": "Product group",
        "Produkte werden im Hintergrund aktualisiert.": "Products are updated in the background.",
        "Jetzt aktualisieren": "Update now",
        "Die Produktdaten werden aus Bexio regelmässig importiert und mit den bestehenden abgeglichen.": "The product data is regularly imported from Bexio and compared with the existing data.",
        "Kürzel (Slug)": "Abbreviation (Slug)",
        "Produktart (Bexio)": "Product type (Bexio)",
        "Shop erfassen": "Enter store",
        "Shop wurde erstellt.": "Store was created.",
        "Shop wurde aktualisiert.": "Store has been updated.",
        "Shop wurde gelöscht.": "Store has been deleted.",
        "Shop bearbeiten": "Edit store",
        "Shops": "Stores",
        "Aktiv": "Active",
        "Produktgruppen auswählen": "Select product groups",
        "Titel": "Title",
        "Mit der Registrierung akzeptiere ich die {tos}. Die {privacyPolicy} habe ich gelesen und verstanden.": "By registering, I accept the {tos}. I have read and understood the {privacyPolicy}.",
        "Es muss eine gültige Handynummer angegeben werden.": "A valid cell phone number must be provided.",
        "inkl. {taxPercentage}% MwSt.": "incl. {taxPercentage}% VAT.",
        "Preis (inkl. {taxPercentage}% MwSt)": "Price (incl. {taxPercentage}% VAT)",
        "Bestelldatum: {orderDate}": "Order date: {orderDate}",
        "Bestellnummer: {orderReference}": "Order number: {orderReference}",
        "Keine Teilnehmenden|ein:e Teilnehmer:in|{capacity} Teilnehmende": "No participants|one participant|{capacity} participants",
        "Kursmodi": "Course modes",
        "Die Anmeldefrist ist abgelaufen.": "The registration deadline has expired.",
        "Kunde": "Customer",
        "Kein Datum definiert": "No date defined",
        "Anmeldefrist abgelaufen": "Registration deadline expired",
        "Anmeldefrist:": "Registration deadline:",
        "Anmeldefrist": "Registration deadline",
        "Link": "Link",
        "eLearning öffnen": "Open eLearning",
        "Du hast dich erfolgreich für das Erste Hilfe eLearning von {customer} angemeldet! 🎉": "You have successfully registered for the First Aid eLearning from {customer}! 🎉",
        "Es steht dir nun zur Verfügung und du kannst ab sofort mit dem Lernen beginnen.": "It is now available to you, and you can start learning immediately.",
        "Einzelkunden": "Private customers",
        "Du hast dich für das Erste Hilfe eLearning von {customer} angemeldet, aber noch nicht gestartet. Nutze die Chance, dein Wissen aufzufrischen.": "You have registered for the First Aid eLearning from {customer} but have not yet started. Take the opportunity to refresh your knowledge.",
        "Starte jetzt dein Erste Hilfe eLearning! 🚀": "Start your First Aid eLearning now! 🚀",
        "Nachricht konnte nicht erneut zugestellt werden!": "Message could not be delivered again!",
        "Der Kommentar konnte nicht gespeichert werden.": "The comment could not be saved.",
        "{firstname}, dein Erste Hilfe eLearning von {company} wartet auf dich! ⏳": "{firstname}, your First Aid eLearning from {company} is waiting for you! ⏳",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning! 🚀": "{firstname}, start your first aid eLearning now! 🚀",
        "Glückwunsch, {firstname}! Dein Erste Hilfe eLearning von {company} ist abgeschlossen 🎓": "Congratulations, {firstname}! Your first aid eLearning from {company} has been completed 🎓",
        "{firstname}, dein Zugang zum Erste Hilfe eLearning von {company} ist da!": "{firstname}, your access to the First Aid eLearning from {company} is here!",
        "Twint": "Twint",
        "Visa": "Visa",
        "MasterCard": "MasterCard",
        "PostFinance Pay": "PostFinance Pay",
        "Rechnung": "Invoice",
        "Vorkasse": "Prepayment",
        "Einzelkund:in wurde erfolgreich aktualisiert.": "Private customer has been successfully updated.",
        "Einzelkund:in wurde erfolgreich gelöscht.": "Private customer was successfully deleted.",
        "Möchtest du die Teilnahmebestätigungen herunterladen?": "Would you like to download the certificates of participation?",
        "Einzelkunden erfassen": "Record private customers",
        "Das eLearning dauert rund {onlineCourseDuration}. Am Ende des eLearnings gibt es eine kurze Lernkontrolle. Es lohnt sich also, die Theorie nicht einfach durchzuklicken!": "The eLearning takes around {onlineCourseDuration}. There is a short learning assessment at the end of the eLearning. It is therefore worthwhile not simply clicking through the theory!",
        "Du hast das Erste Hilfe eLearning von {customer} erfolgreich abgeschlossen – grossartig!": "You have successfully completed the First Aid eLearning from {customer} - great!",
        "Danke, dass du dich für Erste Hilfe weitergebildet hast. Bleib sicher!": "Thank you for your First Aid training. Stay safe!",
        "Wie waren die Aufgaben in den Notfällen für dich?": "How did you find the tasks in the emergencies?",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning von {company}! 🚀": "{firstname}, start your First Aid eLearning from {company} now! 🚀",
        "Viel Erfolg! 🚀": "Good luck! 🚀",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa {duration} ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "It's time to start your eLearning! Please complete it by the start of the course on <strong>{courseStart}</strong>, as it forms the important basis for our course. Allocate approximately {duration} for it, so you are well-prepared and can get the most out of the course. We look forward to seeing you!",
        "Du hast bereits {courseProgress}% vom Erste Hilfe eLearning geschafft – super! 🎉": "You have already completed {courseProgress}% of the First Aid eLearning - great! 🎉",
        "Bleib dran und lerne weiter, damit du den Kurs erfolgreich abschliessen kannst.": "Keep at it and keep learning so that you can successfully complete the course.",
        "Weiterlernen": "Continue learning",
        "Keine Kursleitung definiert": "No course instructor defined",
        "Hat die Lernkontrolle im eLearning bestanden.": "Has passed the learning assessment in eLearning.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für diesen Onlinekurs zu übermitteln. Die Datei findest du im Anhang dieser E-Mail.": "We are pleased to send you your confirmation of participation for this online course. You will find the file attached to this e-mail.",
        "hat am <strong>{dateText}</strong> am {durationText} {courseTypeName} nach SRC Richtlinien 2021 teilgenommen.": "took part in <strong>{dateText}</strong> on {durationText} {courseTypeName} according to SRC guidelines 2021.",
        "hat am <strong>{dateText}</strong> das {durationText} {courseTypeName} nach SRC Richtlinien 2021 erfolgreich abgeschlossen.": "has successfully completed the {durationText} {courseTypeName} according to SRC guidelines 2021 on <strong>{dateText}</strong>.",
        "{minutes}-minütige": "{minutes}-minute",
        "{hours}-stündige": "{hours}-hour",
        "{hours}-stündigen": "{hours}-hours",
        "Es wurden total:": "A total of:",
        "Teilnehmerliste importieren": "Import participant list",
        "Teilnehmer:innen wurden {statusHint} aus Datei {fileName} in Kurs {course} importiert.": "Participants were imported {statusHint} from file {fileName} in course {course}.",
        "Sortierung": "Sorting",
        "Kategorie-Label": "Category label",
        "Die Teilnehmer:innen aus der Datei \"{fileName}\"\" wurden {statusHint} im Kurs {course} erfasst.": "The participants from the file \"{fileName}\"\" were entered {statusHint} in the course {course}.",
        "<strong>{count}</strong> neu angelegt": "<strong>{count}</strong> newly created",
        "<strong>{count}</strong> aktualisiert": "<strong>{count}</strong> updated",
        "<strong>{count}</strong> am Kurs angemeldet": "<strong>{count}</strong> registered for the course",
        "Die Teilnehmer:innen haben eine Anmeldebestätigung erhalten.": "The participants have received a confirmation of registration.",
        "Folgende Zeilen konnten aufgrund ungültiger Daten (<span class=\"text-red\">rot markiert</span>) nicht importiert werden:": "The following lines could not be imported due to invalid data (<span class=\"text-red\">marked red</span>):",
        "messages": {
            "communication": {
                "notifications": {
                    "status": {
                        "skipped": "Skipped",
                        "failed": "Failed",
                        "sending": "Sending...",
                        "sent": "Sent"
                    },
                    "channel": {
                        "database": "Database",
                        "twilio": "SMS",
                        "mail": "Email"
                    }
                }
            },
            "feedback": {
                "moods": {
                    "negative": "Negative",
                    "neutral": "Neutral",
                    "positive": "Positive"
                }
            },
            "elearning": {
                "stories": {
                    "status": {
                        "translation": "In translation",
                        "done": "Finished",
                        "draft": "In progress",
                        "review": "Review"
                    },
                    "subject_focus": {
                        "adults": "Adults",
                        "teenagers": "Teenagers (10-15)",
                        "children": "Children (4-9)",
                        "babies": "Babies (0-3)"
                    },
                    "subject_focus_list": {
                        "adults": "Adults",
                        "teenagers": "Teenagers (10-15)",
                        "children": "Children (4-9)",
                        "babies": "Babies (0-3)"
                    }
                }
            },
            "shop": {
                "products": {
                    "type": {
                        "service": "Service",
                        "goods": "Goods"
                    },
                    "status": {
                        "active": "Active",
                        "inactive": "Inactive",
                        "archived": "Archived"
                    }
                }
            },
            "general": {
                "duration": {
                    "days": "one day|{count} days",
                    "months": "one month|{count} months",
                    "years": "one year|{count} years"
                }
            }
        }
    },
    "fr": {
        "Anmelden": "Se connecter",
        "E-Mail": "E-mail",
        "Passwort": "Mot de passe",
        "Passwort vergessen?": "Mot de passe oublié ?",
        "Eine Woche lang angemeldet bleiben": "Rester connecté pendant une semaine",
        "Willkommen bei": "Bienvenue chez",
        "Passwort zurücksetzen": "Réinitialiser le mot de passe",
        "Zurück zur Anmeldung": "Retour à l'inscription",
        "Link anfordern": "Demander un lien",
        "zurück": "retour",
        "Liste exportieren": "Exporter la liste",
        "Anmeldungen": "Inscriptions",
        "Abmeldungen": "Désinscriptions",
        "Vorname": "Prénom",
        "Nachname": "Nom de famille",
        "Kurssprache": "Langue du cours",
        "Beschreibung": "Description",
        "Link ist aktiv": "Le lien est actif",
        "Kurs freigeben": "Valider le cours",
        "Es existieren keine Daten.": "Il n'existe pas de données.",
        "Willkommen": "Bienvenue",
        "Teilnehmende": "Participant-e-s",
        "Sortieren nach": "Trier par",
        "Datum": "Date",
        "Plätze frei": "Places libres",
        "ausgebucht": "complet",
        "Weitere Person anmelden": "Inscrire une autre personne",
        "An-/Abmeldung": "Inscription/désinscription",
        "Ja, ich nehme teil": "Oui, je participe",
        "Nein, ich bin verhindert": "Non, je suis indisponible",
        "Ich akzeptiere die": "J'accepte les",
        "allgemeinen Geschäftsbedingungen": "Conditions générales",
        "Die": "La",
        "Datenschutzerklärung": "Déclaration de confidentialité",
        "habe ich gelesen und verstanden.": "j'ai lu et compris.",
        "Absenden": "Envoyer",
        "Fragen oder Probleme?": "Des questions ou des problèmes ?",
        "Geburtsdatum": "Date de naissance",
        "Geburtsdatum (TT.MM.JJJJ)": "Date de naissance (jj.mm.aaaa)",
        "Hallo": "Salut",
        "Anmeldebestätigung": "Confirmation d'inscription",
        "Erste Hilfe Kurs": "Cours de premiers secours",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Merci beaucoup pour ton inscription au cours de premiers secours. Voici encore une fois les informations les plus importantes",
        "Liebe Grüsse": "Meilleures salutations",
        "Dein Sanio-Team": "Ton équipe Sanio",
        "Copyright": "Droits d'auteur",
        "Umbuchungsbestätigung": "Confirmation de modification de réservation",
        "Terminerinnerung": "Rappel de rendez-vous",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Le moment est venu et le cours de premiers secours aura lieu bientôt. Voici à nouveau les informations les plus importantes :",
        "Deutsch": "Allemand",
        "Französisch": "Français",
        "Italienisch": "Italien",
        "Englisch": "Anglais",
        "Spanisch": "Espagnol",
        "Portugiesisch": "Portugais",
        "Kurse": "Cours",
        "Impersonation beenden": "Arrêter l'impersonation",
        "Instructors": "Responsable du cours",
        "Teachers": "Instructeur-rice-s d'auto-école",
        "Kursleiterinnen": "Responsables de cours",
        "The {attribute} must contain at least one letter.": "{attribute} doit contenir au moins un caractère.",
        "The {attribute} must contain at least one number.": "{attribute} doit contenir au moins un chiffre.",
        "The {attribute} must contain at least one symbol.": "{attribute} doit contenir au moins un caractère spécial.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} doit contenir au moins une lettre majuscule et une lettre minuscule.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Le {attribute} est déjà apparu dans une fuite de données. Merci de choisir un autre {attribute}.",
        "INSURANCE_FLAT": "Frais administratifs",
        "lessons": "Leçons de conduite",
        "vku": "Cours de sensibilisation à la circulation",
        "insurance": "Assurance",
        "app": "Application théorique",
        "voice_status_start": "Début",
        "voice_status_end": "Terminé",
        "voice_status_queue": "File d'attente",
        "voice_status_taken": "en discussion",
        "voice_status_missed": "Manqué",
        "absences": "Absence",
        "exams": "Examens",
        "comments": "Commentaires",
        "employment": "Emploi",
        "course_documents": "Documents du cours",
        "course_manuals": "Instructions du cours",
        "All": "Tout-e-s",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Le fichier {fileName} a été importé {statusHint}.",
        "Adresse": "Adresse",
        "Kursübersicht": "Aperçu du cours",
        "Zurück zum Login": "Retour au Login",
        "Passwort bestätigen": "Confirmer le mot de passe",
        "Dashboard": "Tableau de bord",
        "In welchen Kurs soll {participantName} verschoben werden?": "Dans quel cours {participantName} doit-il/elle être déplacé-e ?",
        "Mehr anzeigen": "Voir plus",
        "{participantName} in Kurs {courseName} verschieben?": "Déplacer {participantName} vers le cours {courseName} ?",
        "Zum Kurs": "Vers le cours",
        "Umbuchungsbestätigung senden": "Envoyer une confirmation de changement de réservation",
        "Zurück": "Retour",
        "Verschieben": "Déplacer",
        "Willkommen {name} 👋": "Bienvenue {name} 👋",
        "Abbrechen": "Annuler",
        "Ähnliche TN": "Participants similaires",
        "erfolgreich": "avec succès",
        "Abmelden": "Se déconnecter",
        "certificates.not-yet-known": "Pas encore connu",
        "certificates.sanio": "Confirmation du cours Sanio",
        "certificates.src": "Certificat SRC",
        "Fehlermeldung": "Message d'erreur",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Les lignes suivantes n'ont pas pu être importées en raison de données non valables (<span class=\"text-red\">marqué en rouge</span>) :",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Les lignes suivantes ont tout de même été importées en raison d'une attribution peu claire.",
        "Handynummer": "Numéro de téléphone portable",
        "Kontaktdaten": "Coordonnées",
        "In Kurs verschieben": "Changer de cours",
        "locations": "Sites",
        "Mitarbeitende": "Collaborateur-rice-s",
        "Spalte": "Colonne",
        "students": "Étudiants",
        "Teilnehmerübersicht öffnen": "Ouvrir l'aperçu des participants",
        "teilweise": "en partie",
        "voice_status_out-of-office": "Absent(e) du bureau",
        "voice_status_voicemail": "Message vocal",
        "Zeile": "Ligne",
        "Email": "E-mail",
        "voice_status_initiated": "Bienvenue",
        "errors.4xx.title": "Erreur inattendue",
        "Diese Anmeldemaske wurde deaktiviert.": "Ce masque de connexion a été désactivé.",
        "errors.4xx.head": "Oops...",
        "errors.4xx.hint": "Une erreur s'est produite. Ta demande n'a pas pu être traitée. Vérifie tes saisies ou essaie à nouveau plus tard. L'erreur nous a été signalée.",
        "errors.5xx.title": "Erreur de serveur interne",
        "errors.5xx.head": "Oh oh...",
        "errors.5xx.hint": "Une erreur inattendue s'est produite. Retourne à la page d'accueil ou réessaie plus tard. L'erreur nous a été signalée.",
        "errors.401.title": "Accès refusé",
        "errors.401.head": "Oups…",
        "errors.401.hint": "Tu n'es pas autorisé à accéder à cette page. Connecte-toi et essaie à nouveau.",
        "errors.403.title": "Accès refusé",
        "errors.403.head": "Oups…",
        "errors.403.hint": "Accès refusé. Tu n'est pas autorisé à visiter cette page. Merci de nous contacter si tu penses qu'il s'agit d'une erreur.",
        "errors.404.title": "Page non trouvée",
        "errors.404.head": "Oups...",
        "errors.404.hint": "La page que tu as demandée n'existe pas. Retourne en arrière ou clique sur le bouton pour accéder à la page d'accueil.",
        "errors.500.title": "Erreur de serveur interne",
        "errors.500.head": "Oh oh...",
        "errors.500.hint": "Une erreur inattendue s'est produite. Retourne à la page d'accueil ou réessaie plus tard. L'erreur nous a été signalée.",
        "errors.503.title": "Mode de maintenance",
        "errors.503.head": "Un instant, s'il te plaît...",
        "errors.503.hint": "Nous effectuons actuellement des travaux de maintenance pour apporter des améliorations et corriger des erreurs afin d'améliorer ton expérience utilisateur. Merci de réessayer dans un moment. Merci pour ta compréhension.",
        "errors.503.hint2": "En cas d'urgence, tu peux nous contacter par e-mail à <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> ou par téléphone au <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Délai d'attente",
        "errors.504.head": "Temps d'attente…",
        "errors.504.hint": "La requête a pris trop de temps. Merci de réessayer plus tard. L'erreur nous a été signalée.",
        "errors.back-to-start": "Vers la page d'accueil",
        "{attribute} is keine gültige Sprache.": "{attribute} n'est pas une langue valide.",
        "Fehler beim Laden der Kurse": "Erreur lors du chargement des cours",
        "Die aktuellen Infos lauten:": "Voici les nouvelles informations :",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "C'est bientôt l'heure du cours \"{courseName}\".",
        "Hier nochmals die wichtigsten Infos:": "Voici encore une fois les informations les plus importantes :",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Tu t'es inscrit-e avec succès au cours \"{courseName}\" 🎉",
        "Kurs-ID": "ID du cours",
        "Zum Kalender hinzufügen": "Ajouter au calendrier",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Aucune adresse e-mail ou numéro de portable n'a été fourni.",
        "Die E-Mail-Adresse ist ungültig.": "L'adresse e-mail n'est pas valide",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Tu as été transféré-e dans le cours \"{courseName}\".",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Si tu as des questions, contacte directement {name} à l'adresse suivante :",
        "oder": "ou",
        "Kursort:": "Lieu du cours :",
        "Kursdaten": "Dates des cours",
        "Die Handynummer ist ungültig.": "Le numéro de portable est invalide.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Tu ne peux malheureusement pas te connecter avec cet e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Avec ce numéro de téléphone tu ne peux malheureusement pas te connecter.",
        "Der Code ist ungültig.": "Le code n'est pas valide.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Merci beaucoup pour ton inscription au cours. Voici encore une fois les informations les plus importantes :",
        "Der Katalog wurde erstellt.": "Le catalogue a été créé.",
        "Der Katalog wurde aktualisiert.": "Le catalogue a été mis à jour.",
        "Der Katalog wurde entfernt.": "Le catalogue a été supprimé.",
        "Das Thema wurde aktualisiert.": "Le sujet a été mis à jour.",
        "Das Thema wurde entfernt.": "Le sujet a été supprimé.",
        "Der Kurs wurde dupliziert 🎉": "Le cours a été dupliqué 🎉",
        "Kurs": "Cours",
        "Text wurde in die Zwischenablage kopiert": "Le texte a été copié dans le presse-papiers",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Le texte n'a pas pu être copié dans le presse-papiers",
        "Das Thema wurde erstellt.": "Le sujet a été créé.",
        "Story wurde erfolgreich kopiert 🎉": "L'histoire a été copiée avec succès 🎉",
        "hier": "ici",
        "Name": "Nom",
        "Eine Variante|{count} Varianten": "Une variante|{count} variantes",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "La section eLearning n'est pas disponible pour ce client.",
        "Geschlecht": "Sexe",
        "Männlich": "masculin",
        "Weiblich": "féminin",
        "Divers": "autre",
        "Projektstandort": "Lieu du projet",
        "Wohnkanton": "Canton de résidence",
        "Sanio Logo": "Logo Sanio",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{$unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si tu ne souhaites plus recevoir d'e-mails de cette liste, tu peux te désinscrire <a href=\"{$unsubscribeListUrl}\" target=\"_blank\">ici</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{$unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Tu ne veux plus recevoir d'offres de notre part ? Tu peux te désinscrire sur <a href=\"{$unsubscribeUrl}\" target=\"_blank\">ici</a>.",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Voice le lien vers nos CG <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\"></a> et vers notre déclaration de confidentialité <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\"></a> .",
        "Hallo {name} 👋": "Salut {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Voici à nouveau les détails les plus importants :",
        "Teilnehmer:innen": "Participant-e-s",
        "Letzter Kurs": "Dernier cours",
        "Nächster Kurs": "Prochain cours",
        "Hinzufügen": "Ajouter",
        "Exportieren": "Exporter",
        "Keinem Kurs zugeordnet": "Non attribué à un cours",
        "Suchbegriff": "Recherche de mots-clés",
        "Dein Sanio Team": "Ton équipe Sanio",
        "Alle": "Tout-e-s",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si tu ne souhaites plus recevoir d'e-mails de cette liste, tu peux te désinscrire <a href=\"{unsubscribeListUrl}\" target=\"_blank\">ici</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Ne souhaites-tu plus recevoir d'offres de notre part ?Tu peux te désinscrire <a href=\"{unsubscribeUrl}\" target=\"_blank\">ici</a>.",
        "Ungruppierte Kurse": "Cours non groupés",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Plusieurs comptes ont été trouvés pour ces informations. Merci de sélectionner le client souhaité.",
        "Anmeldebestätigung erneut zustellen": "Renvoyer la confirmation d'inscription",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "À la fin, il y aura un court contrôle d'apprentissage. Il vaut donc la peine de parcourir les contenus attentivement !",
        "Abmelden / entfernen": "Désinscrire / éliminer",
        "Einladung zum eLearning": "Invitation à l'eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "Nous sommes heureux de t'annoncer que l'eLearning est maintenant disponible pour toi ! Tu peux commencer à apprendre dès maintenant. 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "D'ici le <strong>{courseName}</strong> du <strong>{courseStart}</strong> à <strong>{coursePlace}</strong>, tu dois absolument avoir terminé l'eLearning. Il sert de base pour le cours pratique.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "L'eLearning dure environ 2 heures au total.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "Tu peux accomplir l'eLearning en une seule fois ou en plusieurs étapes.",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Désinscrire/éliminer le/la participant-e du cours",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Veux-tu vraiment désinscrire/retirer le/la participant-e du cours ?",
        "eLearning Einladung verschicken": "Envoyer une invitation à l'eLearning",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Veux-tu vraiment envoyer une invitation à l'eLearning ?",
        "Einladung verschicken": "Envoyer une invitation",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Veux-tu vraiment renvoyer la confirmation d'inscription ?",
        "Bestätigung erneut verschicken": "Renvoyer la confirmation",
        "Teilnehmer:in abmelden": "Désinscire le/la participant-e",
        "Bemerkungen": "Remarques",
        "Wert fehlt!": "Valeur manquante !",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} Aucun·e participant·e n'a été créé·e et {totalUpdated} mis à jour.|{1} Un-e participant-e a été créé-e et {totalUpdated} mis à jour.|[2,*] {count} participant-e-s ont été créé-e-s et {totalUpdated} mis à jour.",
        "Archivieren": "Archiver",
        "Zu Kursgruppe hinzufügen": "Ajouter au groupe",
        "Neue Kursgruppe erstellen": "Créer un nouveau groupe",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Tu veux vraiment supprimer définitivement le cours ? Cette action ne peut pas être annulée.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Tu veux vraiment archiver le cours ? Le cours n'est ensuite plus visible, mais peut être restauré par un admin.",
        "Freigabelink löschen": "Supprimer le lien de partage",
        "Möchtest du den Freigabelink wirklich löschen?": "Tu veux vraiment supprimer le lien de partage ?",
        "Endgültig löschen": "Supprimer définitivement",
        "Gruppe löschen": "Supprimer le groupe",
        "Möchtest du diese Gruppe wirklich löschen?": "Veux-tu vraiment supprimer ce groupe ?",
        "Kurs aus Gruppe entfernen": "Supprimer le cours du groupe",
        "Kurs duplizieren": "Dupliquer le cours",
        "Kurs archivieren": "Archiver le cours",
        "Kurs löschen": "Supprimer le cours",
        "Der Freigabelink wurde gelöscht! 🎉": "Le lien de partage a été supprimé ! 🎉",
        "Link teilen": "Partager le lien",
        "Gruppe bearbeiten": "Modifier le groupe",
        "Neue:n Teilnehmer:in anmelden": "Inscrire un(e) nouveau/nouvelle participant(e)",
        "nimmt teil": "participe",
        "Ähnliche Teilnehmer:innen": "Participant-e-s similaire-s",
        "Ein unerwarteter Fehler ist aufgetreten": "Une erreur inattendue s'est produite",
        "Bereits in diesem Kurs vorhanden": "Déjà présent dans ce cours",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "Aucun-e participant-e similaire n'a été trouvé-e.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Remplis le formulaire et la recherche commencera.",
        "Suche ähnliche Teilnehmer:innen...": "Recherche de participant-e-s similaires...",
        "eLearning nicht gefunden": "eLearning non trouvé",
        "Wie hat dir das eLearning allgemein gefallen?": "Comment as-tu trouvé l'eLearning de manière générale ?",
        "🤩 Sehr gut!": "🤩 Très bien !",
        "😊 Gut": "😊 Bien",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Pas très bien",
        "😩 Schlecht": "😩 Mauvais",
        "Bitte wähle eine Antwort aus.": "Merci de choisir une réponse.",
        "Wie verständlich waren für dich die Lerninhalte?": "Dans quelle mesure les contenus de l'eLearning étaient-ils compréhensibles pour toi ?",
        "🤩 Sehr verständlich!": "🤩 Très compréhensible !",
        "😊 Verständlich": "😊 Compréhensible",
        "😕 Nicht so verständlich": "😕 Pas très compréhensible",
        "😩 Gar nicht verständlich": "😩 Pas du tout compréhensible",
        "🤩 Sehr einfach!": "🤩 Très simple !",
        "😊 einfach": "😊 simple",
        "😐 Nicht so einfach": "😐 Pas si simple que ça",
        "😕 Schwierig": "😕 Difficile",
        "😩 Sehr schwierig": "😩 Très difficile",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "Comment évalues-tu la facilité d'utilisation de l'eLearning ?",
        "🤩 Super einfache Bedienung!": "🤩 Super facile à utiliser !",
        "😊 Einfach zu bedienen": "😊 Facile à utiliser",
        "😐 Teilweise etwas mühsam": "😐 Parfois un peu laborieux",
        "😩 Richtig mühsame Bedienung": "😩 Utilisation vraiment pénible",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "À quel point as-tu trouvé le test de connaissances à la fin difficile ?",
        "Warum war die Lernkontrolle für dich einfach?": "Pourquoi le contrôle des connaissances était-il facile pour toi ?",
        "Überspringen": "Sauter",
        "Warum war die Lernkontrolle für dich schwierig?": "Pourquoi le contrôle des connaissances était-il difficile pour toi ?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "Comment pouvons-nous améliorer l'eLearning ? Qu'est-ce qui t'a manqué ?",
        "Würdest du das eLearning weiterempfehlen?": "Recommanderais-tu l'eLearning ?",
        "🤩 Ja klar!": "🤩 Bien sûr !",
        "😊 Ja, wahrscheinlich schon": "😊 Oui, probablement",
        "😐 Weiss nicht recht": "😐 Je ne sait pas trop",
        "😕 Eher nicht": "😕 Plutôt non",
        "😩 Nein, auf keinen Fall": "😩 Non, en aucun cas",
        "Vielen Dank für deine Antworten!": "Merci beaucoup pour tes réponses !",
        "Cool hast du dir die Zeit genommen!": "C'est cool que tu aies pris le temps !",
        "Fehler beim Laden der Suchergebnisse": "Erreur lors du chargement des résultats de recherche",
        "Speichern": "Enregistrer",
        "Jetzt mit dem eLearning starten": "Commencer le eLearning maintenant",
        "Zu Kurs zugeordnet": "Attribué au cours",
        "Teilnehmer:innen {company}": "Participants {company}",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, n’oublie pas l'eLearning pour le cours de {company} !",
        "Dein my Sanio Login Code": "Ton code de connexion my Sanio",
        "Dein my.sanio Verifizierungscode": "Ton code de vérification my.sanio",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "J’accepte les {tos} et j’ai lu et compris la {privacyPolicy}.",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "Les CG et la déclaration de confidentialité doivent être acceptées.",
        "Der Kurs ist leider bereits ausgebucht.": "Malheureusement, le cours est déjà complet.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Nous n'avons malheureusement pas pu t'inscrire au cours.",
        "Profil": "Profil",
        "Zukünftige Kurse": "Cours futurs",
        "Vergangene Kurse": "Cours passés",
        "eLearning": "eLearning",
        "Fortschritt:": "Progrès :",
        "Dauer:": "Durée :",
        "Inhalt": "Contenu",
        "Von:": "De :",
        "An:": "À :",
        "CC:": "CC :",
        "BCC:": "CCI :",
        "Persönliche Daten": "Données personnelles",
        "Kommunikation": "Communication",
        "unbekannter Betreff": "Objet inconnu",
        "Zeit": "Temps",
        "Status": "Statut",
        "Kanal": "Canal",
        "Antwort an:": "Réponse à :",
        "Anhänge": "Annexes",
        "Nicht dargestellte Kursdaten": "Dates de cours non affichées",
        "Benutzerbild": "Image d'utilisateur",
        "Meine Kurse": "Mes cours",
        "Dashboard Instruktor:innen": "Tableau de bord des instructeurs",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "Si nous trouvons un compte pour cette adresse e-mail, tu recevras bientôt un e-mail avec un lien pour te connecter (vérifie également ton dossier de spam).",
        "Jetzt einloggen": "Se connecter maintenant",
        "Link zum Einloggen": "Lien de connexion",
        "Teilnahme-Status erfolgreich aktualisiert.": "Statut de participation mis à jour avec succès.",
        "Team": "Équipe",
        "Anwesenheit": "Présence",
        "😌 Du hast keine vergangenen Kurse.": "😌 Tu n'as pas de cours passés.",
        "Gemäss Kursleiter:in anwesend": "Présent-e selon l’instructeur-rice",
        "Gemäss Kursleiter:in abwesend": "Absent-e selon l’instructeur-rice",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 Tu n'as pas de cours cette semaine.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 Tu n'as pas de cours futurs.",
        "Diese Woche": "Cette semaine",
        "und": "et",
        "Geschäftsführerin Sanio AG": "Directrice générale Sanio AG",
        "Zertifikat": "Certificat",
        "Teilnahmebestätigung": "Attestations de participation",
        "Kursleitung:": "Direction du cours :",
        "Dieses Zertifikat ist bis am {date} gültig.": "Ce certificat est valable jusqu'au {date}.",
        "Eine Auffrischung der Erste Hilfe Grundlagen alle 2 Jahre wird empfohlen.": "Un rappel des bases des premiers secours est recommandé tous les deux ans.",
        "Teilnahmebestätigung wurde erfolgreich generiert und heruntergeladen.": "L'attestation de participation a été générée et téléchargée avec succès.",
        "Fehler beim Erstellen der Teilnahmebestätigung.": "Erreur lors de la création de l'attestation de participation.",
        "Teilnehmerliste exportieren": "Exporter la liste des participants",
        "Teilnahmebestätigungen als PDF": "Attestation de participation en PDF",
        "Teilnahmebestätigungen als ZIP": "Attestation de participation en ZIP",
        "Download wird gestartet...": "Téléchargement en cours…",
        "Download erfolgreich abgeschlossen.": "Téléchargement terminé avec succès.",
        "Download fehlgeschlagen.": "Échec du téléchargement.",
        "Generiere Teilnahmebestätigungen...": "Génération des attestations de participation en cours...",
        "Teilnahmebestätigungen wurden erfolgreich generiert und heruntergeladen.": "Les attestations de participation ont été générées et téléchargées avec succès.",
        "Fehler beim Erstellen der Teilnahmebestätigungen.": "Erreur lors de la création des attestations de participation.",
        "Generiere Teilnahmebestätigung...": "Création d'une attestation de participation en cours...",
        "Bearbeiten": "Modifier",
        "Löschen": "Supprimer",
        "Schreibe deinen Kommentar...": "Écris ton commentaire…",
        "Zurücksetzen": "Réinitialiser",
        "Kommentieren": "Commenter",
        "Kommentar bearbeiten": "Modifier le commentaire",
        "wurde erstellt": "a été créée",
        "kommentierte": "a commenté",
        "Aktivität": "Activité",
        "Dieses Objekt": "Cet objet",
        "Teilnehmer:in hinzufügen": "Ajouter un participant",
        "Teilnahmebestätigung herunterladen": "Télécharger l'attestation de participation",
        "Teilnehmer abgleichen": "Faire correspondre les participants",
        "Der Kurs ist ausgebucht.": "Le cours est complet.",
        "abgeschlossen": "terminé",
        "Der Kurs ist bereits beendet.": "Le cours est déjà terminé.",
        "{capacity} Teilnehmende": "{capacity} participants",
        "{availableSpots} Plätze frei": "{availableSpots} places disponibles",
        "Es sind keine anderen Kurse verfügbar.": "Il n'y a pas d'autres cours disponibles.",
        "Teilnehmer wurde in neuen Kurs verschoben.": "Le participant a été déplacé dans un nouveau cours.",
        "Beendete Kurse können nicht mehr bearbeitet werden.": "Les cours terminés ne peuvent plus être modifiés.",
        "certificates.none": "Pas de certificat",
        "Der Benutzer wurde erfolgreich erstellt.": "L'utilisateur a été créé avec succès.",
        "vollständig": "complet",
        "Auswählen": "Sélectionner",
        "Teilnehmer:in anzeigen": "Afficher le/la participant-e",
        "Kurstypen": "Types de cours",
        "Keine Daten gefunden.": "Aucune donnée trouvée.",
        "Nachricht wurde erneut zugestellt 🎉": "Le message a été renvoyé 🎉",
        "Die Weiterleitung wurde gelöscht!": "La redirection a été supprimée !",
        "Der Kontakt wurde entfernt.": "Le contact a été supprimé.",
        "Der Kundenbereich wurde eröffnet! 🎉": "L'espace client a été ouvert ! 🎉",
        "Der Import wurde gestartet und wird im Hintergrund ausgeführt.": "L'importation a été lancée et est exécutée en arrière-plan.",
        "Kurse wurden der Gruppe hinzugefügt.": "Des cours ont été ajoutés au groupe.",
        "Möchtest du das Element wirklich löschen?": "Veux-tu vraiment supprimer l'élément ?",
        "Der Benutzer wurde erfolgreich aktualisiert.": "L'utilisateur a été mis à jour avec succès.",
        "Kurs erstellen": "Créer un cours",
        "Teilnehmer wurde gelöscht!": "Le participant a été supprimé !",
        "Das Login wurde gelöscht! 🎉": "Le login a été supprimé ! 🎉",
        "Kursgruppe wurde gelöscht!": "Le groupe de cours a été supprimé !",
        "Der Kurs wurde erfolgreich gelöscht!": "Le cours a été supprimé avec succès !",
        "Kurstyp wurde erfolgreich aktualisiert.": "Le type de cours a été mis à jour avec succès.",
        "Nachrichtenverlauf": "Historique des messages",
        "Kurstyp bearbeiten": "Modifier le type de cours",
        "Kurs hat noch nicht begonnen.": "Le cours n'a pas encore commencé.",
        "Kurs bearbeiten": "Modifier le cours",
        "HINWEIS:": "REMARQUE :",
        "Die Offerte wurde erstellt.": "L'offre a été établie.",
        "Das Angebot wurde aktualisiert.": "L'offre a été mise à jour.",
        "Das Angebot wurde entfernt.": "L'offre a été supprimée.",
        "Teilnehmer erfolgreich gespeichert!": "Participant-e enregistrés avec succès !",
        "Das Login wurde erstellt! 🎉": "Le login a été créé ! 🎉",
        "Der Kundenbereich wurde gespeichert! 🎉": "L'espace client a été enregistré ! 🎉",
        "Nachricht wurde verschickt 🎉": "Le message a été envoyé 🎉",
        "Kursgruppe erfolgreich erstellt 🎉": "Le groupe de cours créé avec succès 🎉",
        "Kursgruppe erfolgreich aktualisiert 🎉": "Le groupe de cours mis à jour avec succès 🎉",
        "Geteilter Link gelöscht!": "Lien partagé supprimé !",
        "Teilnehmer wurde in den neuen Kurs verschoben.": "Le participant a été déplacé dans le nouveau cours.",
        "Der Kurs wurde gespeichert 🎉": "Le cours a été enregistré 🎉",
        "Der Kurs wurde gespeichert": "Le cours a été enregistré",
        "Der Kurs wurde erfolgreich archiviert!": "Le cours a été archivé avec succès !",
        "Teilnehmer:in erfolgreich abgemeldet.": "Participant-e désinscrit-e avec succès.",
        "Der Benutzer wurde erfolgreich gelöscht.": "L'utilisateur a été supprimé avec succès.",
        "Die Weiterleitung wurde erstellt!": "Le cours a été archivé avec succès !",
        "Die Weiterleitung wurde aktualisiert!": "La redirection a été mise à jour !",
        "Mail Statusinformationen wurden aktualisiert.": "Les informations sur le statut du courrier ont été mises à jour.",
        "Das Unternehmen wurde erstellt.": "L'entreprise a été créée.",
        "Das Unternehmen wurde aktualisiert.": "L'entreprise a été mise à jour.",
        "Das Unternehmen wurde gelöscht!": "L'entreprise a été supprimée !",
        "Der Kontakt wurde aktualisiert.": "Le contact a été mis à jour.",
        "Der Kommentar wurde gespeichert.": "Le commentaire a été enregistré.",
        "Der Kommentar wurde aktualisiert.": "Le commentaire a été mis à jour.",
        "Der Kommentar wurde entfernt.": "Le commentaire a été supprimé.",
        "Der Kontakt wurde erstellt.": "Le contact a été créé.",
        "Link für gesamte Kursliste kopieren": "Copier le lien pour la liste complète des cours",
        "Link für diese Kursgruppe kopieren": "Copier le lien pour ce groupe de cours",
        "Willkommen im": "Bienvenue au",
        "Instruktor:innen Bereich": "Domaine des instructeurs",
        "Der Login-Link ist ungültig oder abgelaufen.": "Le lien de connexion est invalide ou a expiré.",
        "weiter": "continuer",
        "+{more} weitere": "+{more} d'autres",
        "Notfallnummer (für Kurse)": "Numéro d'urgence (pour les cours)",
        "Telefonzeiten": "Heures d'appel téléphonique",
        "Montag bis Freitag": "du lundi au vendredi",
        "9.00 bis 12.00 Uhr": "de 9h00 à 12h00",
        "13.00 bis 16.00 Uhr": "de 13h00 à 16h00",
        "Link für diesen Kurs kopieren": "Copier le lien pour ce cours",
        "Link für Anmeldung teilen": "Partager le lien d'inscription",
        "🤔 Es sind keine Kurse geplant.": "🤔 Aucun cours n'est prévu.",
        "Suchen...": "Chercher...",
        "Kursinstruktor:in": "Instructeur-rice de cours",
        "Management": "Gestion",
        "Büro": "Bureau",
        "Schule": "École",
        "Berufsschule": "École professionnelle",
        "Gymnasium": "Collège",
        "Kita": "Crèche",
        "Betrieb": "Entreprise",
        "Vereine": "Associations",
        "Sonstiges": "Autres",
        "Teilnahmebestätigung Erste Hilfe Kurs": "Attestation de participation au cours de premiers secours",
        "Zertifikat BLS AED SRC Kurs": "Certificat de cours BLS AED SRC",
        "Die Datei findest du im Anhang dieser E-Mail.": "Tu trouveras le fichier en annexe à cet e-mail.",
        "Herzlichen Glückwunsch und weiterhin viel Erfolg!": "Toutes nos félicitations et beaucoup de succès pour la suite !",
        "Wir freuen uns, dir dein Zertifikat für den <strong>{courseName}</strong> zu übermitteln.": "Nous avons le plaisir de te transmettre ton certificat pour le <strong>{courseName}</strong>.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für den <strong>{courseName}</strong> zu übermitteln.": "Nous avons le plaisir de te transmettre ta confirmation de participation pour le <strong>{courseName}</strong>.",
        "Zertifikate werden nun generiert und an die Teilnehmer:innen verschickt 🎉": "Les certificats sont maintenant générés et envoyés aux participants 🎉",
        "ACHTUNG:": "ATTENTION :",
        "Zertifikat wird nun generiert und an den/die Teilnehmer:in verschickt 🎉": "Le certificat est maintenant généré et envoyé au participant 🎉",
        "Teilnahmebestätigungen an Teilnehmer versenden": "Envoyer des confirmations de participation aux participants",
        "Teilnahmebestätigungen per Mail zustellen": "Envoyer les confirmations de participation par e-mail",
        "Möchtest du die Teilnahmebestätigungen an alle Teilnehmer:innen zustellen?": "Veux-tu envoyer les attestations de participation à tous les participant-e-s ?",
        "Für einige Teilnehmer:innen, die Anspruch auf eine Teilnahmebestätigung haben, liegt keine E-Mail-Adresse vor. Daher kann die Bestätigung an die folgenden Personen nicht per Mail zugestellt werden:": "Pour certain-e-s participant-e-s qui ont droit à une attestation de participation, aucune adresse e-mail n'est disponible. Par conséquent, la confirmation ne peut pas être envoyée par e-mail aux personnes suivantes :",
        "Bestätigung zustellen": "Envoyer la confirmation",
        "Teilnahmebestätigung per Mail versenden": "Envoyer la confirmation de participation par e-mail",
        "Willst du wirklich die Teilnahmebestätigung per Mail versenden?": "Veux-tu vraiment envoyer la confirmation de participation par e-mail ?",
        "Füge deine E-Mail-Adresse ein, um eine Anmeldebestätigung und ein Erinnerungsmail zu erhalten.": "Ajoute ton adresse e-mail pour recevoir une confirmation d'inscription et un e-mail de rappel.",
        "Du bekommst von uns einen Link per E-Mail zugesendet, um dein Passwort zurückzusetzen. Bitte gib deine E-Mail-Adresse ein, die du zum Login im Sanio Kundenportal verwendest.": "Tu recevras un lien par e-mail de notre part pour réinitialiser ton mot de passe. Merci de saisir l'adresse e-mail que tu utilises pour te connecter au portail client Sanio.",
        "Sanio Kundenbereich": "Espace client Sanio",
        "Liebe Geschäftskund:innen, unser Portal für die Kursverwaltung hat einen neuen Namen. Das {portalName} erreicht ihr ab sofort unter {sanioPortalLink}.": "Chèrs clients professionnels, notre portail pour la gestion des cours a un nouveau nom. Vous pouvez désormais accéder au {portalName} à l'adresse {sanioPortalLink}.",
        "Teilnahmebestätigungen herunterladen": "Télécharger les attestations de participation",
        "Hier dein persönlicher Zugang, um dich im my.sanio einzuloggen:": "Voici ton accès personnel pour te connecter à my.sanio :",
        "Wichtige Hinweise:": "Remarques importantes :",
        "Der Link kann nur einmal verwendet werden.": "Le lien ne peut être utilisé qu'une seule fois.",
        "Er ist zeitlich begrenzt gültig.": "Il est valable pour une durée limitée.",
        "Falls das Login nicht funktioniert, kannst du jederzeit einen neuen Link auf der <a href=\"{loginPageUrl}\">Anmeldeseite</a> anfordern.": "Si l'accès ne fonctionne pas, tu peux à tout moment demander un nouveau lien à la <a href=\"{loginPageUrl}\">page de login</a>.",
        "Hier dein persönlicher Zugang, um dich im Sanio Kundenportal einzuloggen:": "Voici ton accès personnel pour te connecter au portail clients de Sanio :",
        "Hier dein persönlicher Zugang, um dich im Sanio Instruktor:innen Bereich einzuloggen:": "Voici ton accès personnel pour te connecter à l'espace instructeurs-rices de Sanio :",
        "in Planung": "en cours de planification",
        "Füge eine persönliche E-Mail-Adresse ein, damit der:die Teilnehmer:in alle kursbezogenen Mitteilungen (z. B. Anmeldebestätigung, Erinnerung, Teilnahmebestätigung) direkt per Mail erhält.": "Ajoute une adresse e-mail personnelle afin que le/la participant-e reçoive directement par e-mail toutes les communications liées au cours (par exemple, confirmation d'inscription, rappel, attestation de participation).",
        "am <strong>{dateStart}</strong> und <strong>{dateEnd}</strong>": "le <strong>{dateStart}</strong> et le <strong>{dateEnd}</strong>",
        "vom <strong>{dateStart}</strong> bis <strong>{dateEnd}</strong>": "dès le <strong>{dateStart}</strong> au <strong>{dateEnd}</strong>",
        "am <strong>{date}</strong>": "le <strong>{date}</strong>",
        "Dein Verifizierungscode lautet: <strong>{code}</strong>": "Ton code de vérification est : <strong>{code}</strong>",
        "Dein Sanio-Verifizierungscode": "Ton code de vérification Sanio",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im Sanio Kundenportal mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Si un mot de passe a été enregistré, tu peux te connecter au portail client Sanio avec ton e-mail et ton mot de passe. Sinon, sinon un lien d'accès te sera envoyé.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im team.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Si un mot de passe a été enregistré, le contact peut se connecter à team.sanio avec son e-mail et son mot de passe. Sinon, un lien d'accès lui sera envoyé.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im my.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Si un mot de passe a été enregistré, le contact peut se connecter à my.sanio avec son e-mail et son mot de passe. Sinon, un lien d'accès lui sera envoyé.",
        "Einzelkund:in wurde erfolgreich erstellt.": "Le/la client-e individuel-le a été créé-e avec succès.",
        "Einzelkund:in erfassen": "Saisir le/la client-e individuel-le",
        "Einzelkund:in bearbeiten": "Modifier le/la client-e individuel-le",
        "Allgemeine Informationen": "Informations générales",
        "Rechte": "Droits",
        "Rollen": "Rôles",
        "Rollen auswählen": "Sélectionner les rôles",
        "Authentifizierung": "Authentification",
        "Passwort (optional)": "Mot de passe (facultatif)",
        "Wechseln": "Changer",
        "Sprache": "Langue",
        "Benutzeroberfläche": "Interface d'utilisateur",
        "total": "total",
        "übersetzt": "traduit",
        "ausstehend": "en attente",
        "Übersetzungsstatistiken werden geladen": "Chargement des statistiques de traduction",
        "Übersetzungsstatistiken konnten nicht geladen werden.": "Les statistiques de traduction n'ont pas pu être chargées.",
        "Mögliche Gründe:": "Raisons possibles :",
        "Die Story wurde noch nicht ins Weblate übertragen.": "L'histoire n'a pas encore été transférée sur le site web.",
        "Es gab ein technisches Problem.": "Il y a eu un problème technique.",
        "Übersetzungsstatistiken": "Statistiques de traduction",
        "Du musst die AGB akzeptieren!": "Tu dois accepter les Conditions Générales d'Utilisation !",
        "Falls du Excel für den Import verwendest, achte darauf, dass Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Si tu utilises Excel pour l'importation, assure-toi que les colonnes, comme la date de naissance par exemple, sont formatées en tant que texte.",
        "Du musst die AGB akzeptieren, um fortfahren zu können.": "Tu dois accepter les CG pour pouvoir continuer.",
        "Bitte gib eine gültige Handynummer ein.": "Merci d'insérer un numéro de portable valide.",
        "Es gibt bereits {count} ähnliche:n Teilnehmer:in. Schalte das Matching aus um den Teilnehmer trotzdem zu erstellen.": "Il y a déjà {count} participant-s similaire-s. Désactive le matching pour créer le-s participant-s quand même.",
        "Möchtest du die Variante wirklich duplizieren?": "Veux-tu vraiment dupliquer la variante ?",
        "Vielen Dank! Wir haben deine Angaben erhalten.": "Merci beaucoup ! Nous avons bien reçu tes informations.",
        "Deine Ansprechperson:": "Ta personne de contact :",
        "Deine Ansprechpersonen:": "Tes interlocuteurs :",
        "Preis": "Prix",
        "Produkt erfassen": "Saisir le produit",
        "Produkt bearbeiten": "Modifier le produit",
        "Produktstatus": "Statut du produit",
        "Preis in CHF": "Prix en CHF",
        "MwSt. in Prozent": "TVA en pourcentage",
        "Produktmedien": "Médias du produit",
        "Produktbild": "Image du produit",
        "Produkte": "Produits",
        "Produkt wurde erstellt.": "Le produit a été créé.",
        "Produkt wurde aktualisiert.": "Le produit a été mis à jour.",
        "Produktgruppen": "Groupes de produits",
        "Medien": "Médias",
        "Produktgruppe erfassen": "Saisir le groupe de produits",
        "Produktgruppe bearbeiten": "Modifier le groupe de produits",
        "Es gibt noch keine Einträge.": "Il n'y a pas encore d'entrées.",
        "Produktgruppenbild": "Image du groupe de produits",
        "Produktgruppe wurde erstellt.": "Le groupe de produits a été créé.",
        "Produktgruppe wurde aktualisiert.": "Le groupe de produits a été mis à jour.",
        "Produktgruppe": "Groupe de produits",
        "Produkte werden im Hintergrund aktualisiert.": "Les produits sont mis à jour en arrière-plan.",
        "Jetzt aktualisieren": "Mettre à jour maintenant",
        "Die Produktdaten werden aus Bexio regelmässig importiert und mit den bestehenden abgeglichen.": "Les données des produits sont régulièrement importées depuis Bexio et comparées avec les données existantes.",
        "Kürzel (Slug)": "Abréviation (Slug)",
        "Produktart (Bexio)": "Type de produit (Bexio)",
        "Shop wurde erstellt.": "Le shop a été créé.",
        "Shop wurde aktualisiert.": "Le shop a été mis à jour.",
        "Shop wurde gelöscht.": "Le shop a été supprimé.",
        "Shop erfassen": "Saisir un shop",
        "Shops": "Magasins",
        "Shop bearbeiten": "Modifier le shop",
        "Aktiv": "Actif",
        "Produktgruppen auswählen": "Sélectionner les groupes de produits",
        "Titel": "Titre",
        "Mit der Registrierung akzeptiere ich die {tos}. Die {privacyPolicy} habe ich gelesen und verstanden.": "En m'inscrivant, j'accepte les {tos}. J'ai lu et compris la {privacyPolicy}.",
        "Es muss eine gültige Handynummer angegeben werden.": "Un numéro de portable valide doit être fourni.",
        "inkl. {taxPercentage}% MwSt.": "y compris {taxPercentage}% TVA",
        "Preis (inkl. {taxPercentage}% MwSt)": "Prix (y compris {taxPercentage}% TVA)",
        "Bestelldatum: {orderDate}": "Date de commande : {orderDate}",
        "Bestellnummer: {orderReference}": "Numéro de commande : {orderReference}",
        "Keine Teilnehmenden|ein:e Teilnehmer:in|{capacity} Teilnehmende": "Aucun participant·e|Un·e participant·e|{capacity} participant·e·s",
        "Kursmodi": "Modes de cours",
        "Kein Datum definiert": "Aucune date définie",
        "Anmeldefrist abgelaufen": "Délai d'inscription dépassé",
        "Anmeldefrist:": "Délai d'inscription :",
        "Anmeldefrist": "Délai d'inscription",
        "Die Anmeldefrist ist abgelaufen.": "Le délai d'inscription est dépassé.",
        "Kunde": "Client",
        "Der Link wurde in die Zwischenablage kopiert! 🎉": "Le lien a été copié dans le presse-papiers ! 🎉",
        "Link": "Lien",
        "eLearning öffnen": "Ouvrir l’eLearning",
        "Du hast dich erfolgreich für das Erste Hilfe eLearning von {customer} angemeldet! 🎉": "Tu t'es inscrit avec succès à l’eLearning sur le premiers secours de {customer} ! 🎉",
        "Es steht dir nun zur Verfügung und du kannst ab sofort mit dem Lernen beginnen.": "Il est désormais disponible pour toi et tu peux commencer à étudier dès maintenant.",
        "Du hast dich für das Erste Hilfe eLearning von {customer} angemeldet, aber noch nicht gestartet. Nutze die Chance, dein Wissen aufzufrischen.": "Tu t'es inscrit à l‘eLearning de premiers secours de {customer}, mais tu n'as pas encore commencé. Profite de l'occasion pour rafraîchir tes connaissances.",
        "Starte jetzt dein Erste Hilfe eLearning! 🚀": "Commence dès maintenant ton eLearning sur les premiers secours ! 🚀",
        "Nachricht konnte nicht erneut zugestellt werden!": "Le message n'a pas pu être renvoyé !",
        "Der Kommentar konnte nicht gespeichert werden.": "Le commentaire n'a pas pu être enregistré.",
        "{firstname}, dein Erste Hilfe eLearning von {company} wartet auf dich! ⏳": "{firstname}, ton eLearning de premiers secours de {company} t'attend ! ⏳",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning! 🚀": "{firstname}, commence dès maintenant ton apprentissage en ligne des premiers secours ! 🚀",
        "Glückwunsch, {firstname}! Dein Erste Hilfe eLearning von {company} ist abgeschlossen 🎓": "Félicitations, {firstname} ! Ton eLearning de premiers secours de {company} est terminé 🎓",
        "{firstname}, dein Zugang zum Erste Hilfe eLearning von {company} ist da!": "{firstname}, ton accès à l'apprentissage en ligne des premiers secours de {company} est arrivé !",
        "Twint": "Twint",
        "Visa": "Visa",
        "MasterCard": "MasterCard",
        "PostFinance Pay": "PostFinance Pay",
        "Rechnung": "Facture",
        "Vorkasse": "Paiement anticipé",
        "Einzelkund:in wurde erfolgreich aktualisiert.": "Le/La client privé-e a été-e mis-e à jour avec succès.",
        "Einzelkund:in wurde erfolgreich gelöscht.": "Le/La client individuel-lle a été-e supprimé-e avec succès.",
        "Möchtest du die Teilnahmebestätigungen herunterladen?": "Souhaites-tu télécharger les attestations de participation ?",
        "Einzelkunden": "Clients individuels",
        "Einzelkunden erfassen": "Saisir les clients individuels",
        "Das eLearning dauert rund {onlineCourseDuration}. Am Ende des eLearnings gibt es eine kurze Lernkontrolle. Es lohnt sich also, die Theorie nicht einfach durchzuklicken!": "L'eLearning dure environ {onlineCourseDuration}. À la fin de l'eLearning, il y a un bref contrôle des connaissances. Il vaut donc la peine de ne pas simplement cliquer à travers la théorie !",
        "Du hast das Erste Hilfe eLearning von {customer} erfolgreich abgeschlossen – grossartig!": "Tu as terminé avec succès l'eLearning de premiers secours de {customer} - formidable !",
        "Danke, dass du dich für Erste Hilfe weitergebildet hast. Bleib sicher!": "Merci de t'être formé aux premiers secours. Reste en sécurité !",
        "Wie waren die Aufgaben in den Notfällen für dich?": "Comment as-tu trouvé les tâches dans les situations d'urgence ?",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa {duration} ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "Il est temps de commencer l‘ eLearning ! Merci de le terminer avant le début du cours le <strong>{courseStart}</strong>, car il constitue une base importante pour notre cours. Prévois environ {duration} pour être bien préparé et tirer le meilleur parti du cours. Nous sommes impatients de te voir !",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning von {company}! 🚀": "{firstname}, commence maintenant ton eLearning de premiers secours de {company} ! 🚀",
        "Viel Erfolg! 🚀": "Nous te souhaitons beaucoup de succès ! 🚀",
        "Weiterlernen": "Continuer à apprendre",
        "Du hast bereits {courseProgress}% vom Erste Hilfe eLearning geschafft – super! 🎉": "Tu as déjà réalisé le {courseProgress}% de l'eLearning de premiers secours - super ! 🎉",
        "Bleib dran und lerne weiter, damit du den Kurs erfolgreich abschliessen kannst.": "Persévère et continue d'apprendre afin de réussir le cours.",
        "Keine Kursleitung definiert": "Aucun-e responsable de cours défini-e",
        "Hat die Lernkontrolle im eLearning bestanden.": "A réussi le contrôle des connaissances dans le cadre de l'eLearning.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für diesen Onlinekurs zu übermitteln. Die Datei findest du im Anhang dieser E-Mail.": "Nous avons le plaisir de te transmettre ta confirmation de participation à ce cours en ligne. Tu trouveras le fichier en annexe de cet e-mail.",
        "hat am <strong>{dateText}</strong> am {durationText} {courseTypeName} nach SRC Richtlinien 2021 teilgenommen.": "a participé à <strong>{dateText}</strong> à {durationText} {courseTypeName} selon les directives SRC 2021.",
        "hat am <strong>{dateText}</strong> das {durationText} {courseTypeName} nach SRC Richtlinien 2021 erfolgreich abgeschlossen.": "a passé avec succès le <strong>{dateText}</strong> le {durationText} {courseTypeName} selon les directives SRC 2021.",
        "{minutes}-minütige": "{minutes}-minutes",
        "{hours}-stündige": "{hours}-heure",
        "{hours}-stündigen": "{hours}-heures",
        "Teilnehmerliste importieren": "Importer la liste des participants",
        "Teilnehmer:innen wurden {statusHint} aus Datei {fileName} in Kurs {course} importiert.": "Participants:ont été importés {statusHint} du fichier {fileName} dans le cours {course}.",
        "Sortierung": "Triage",
        "Kategorie-Label": "Label de la catégorie",
        "Die Teilnehmer:innen aus der Datei \"{fileName}\"\" wurden {statusHint} im Kurs {course} erfasst.": "Les participants:du fichier \"{fileName}\"\" ont été enregistrés {statusHint} dans le cours {course}.",
        "Es wurden total:": "Il y a eu au total",
        "<strong>{count}</strong> neu angelegt": "<strong>{count}</strong> nouvellement créé",
        "<strong>{count}</strong> aktualisiert": "<strong>{count}</strong> mis à jour",
        "<strong>{count}</strong> am Kurs angemeldet": "<strong>{count}</strong> inscrits au cours",
        "Die Teilnehmer:innen haben eine Anmeldebestätigung erhalten.": "Les participant(e)s ont reçu une confirmation d'inscription.",
        "Folgende Zeilen konnten aufgrund ungültiger Daten (<span class=\"text-red\">rot markiert</span>) nicht importiert werden:": "Les lignes suivantes n'ont pas pu être importées en raison de données non valables (<span class=\"text-red\">marqué en rouge</span>) :",
        "messages": {
            "communication": {
                "notifications": {
                    "status": {
                        "sending": "En cours d’envoi…",
                        "skipped": "Sauté",
                        "sent": "Envoyé",
                        "failed": "Échoué"
                    },
                    "channel": {
                        "database": "Base de données",
                        "twilio": "SMS",
                        "mail": "E-mail"
                    }
                }
            },
            "feedback": {
                "moods": {
                    "negative": "Négatif",
                    "neutral": "Neutre",
                    "positive": "Positif"
                }
            },
            "elearning": {
                "stories": {
                    "status": {
                        "draft": "En cours",
                        "review": "Revue",
                        "translation": "En traduction",
                        "done": "Terminé"
                    },
                    "subject_focus": {
                        "adults": "Adultes",
                        "teenagers": "Adolescents (10-15)",
                        "children": "Enfants (4-9)",
                        "babies": "Bébés (0-3)"
                    },
                    "subject_focus_list": {
                        "adults": "Adultes",
                        "teenagers": "Adolescents (10-15)",
                        "children": "Enfants (4-9)",
                        "babies": "Bébés (0-3)"
                    }
                }
            },
            "shop": {
                "products": {
                    "type": {
                        "service": "Service",
                        "goods": "Marchandise"
                    },
                    "status": {
                        "active": "Actif",
                        "inactive": "Inactif",
                        "archived": "Archivé"
                    }
                }
            },
            "general": {
                "duration": {
                    "days": "un jour|{count} jours",
                    "months": "un mois|{count} mois",
                    "years": "un an|{count} ans"
                }
            }
        }
    },
    "it": {
        "Anmelden": "Registrarsi",
        "E-Mail": "E-mail",
        "Passwort": "Password",
        "Passwort vergessen?": "Password dimenticata?",
        "Eine Woche lang angemeldet bleiben": "Rimanere connessi per una settimana",
        "Willkommen bei": "Benvenuto/a a",
        "Passwort zurücksetzen": "Reimpostare la password",
        "Zurück zur Anmeldung": "Torna all'accesso",
        "Link anfordern": "Richiedi il link",
        "zurück": "indietro",
        "Liste exportieren": "Esporta l'elenco",
        "Anmeldungen": "Registrazioni",
        "Abmeldungen": "Disdette",
        "Vorname": "Nome",
        "Nachname": "Cognome",
        "Kurssprache": "Lingua del corso",
        "Beschreibung": "Descrizione",
        "Link ist aktiv": "Il collegamento è attivo",
        "Kurs freigeben": "Condividi il corso",
        "Es existieren keine Daten": "Non ci sono dati esistenti.",
        "Willkommen": "Benvenuto/a",
        "Teilnehmende": "Partecipanti",
        "Sortieren nach": "Ordina per",
        "Datum": "Data",
        "Adresse": "Indirizzo",
        "Plätze frei": "Posti liberi",
        "ausgebucht": "al completo",
        "Weitere Person anmelden": "Registrare una persona aggiuntiva",
        "An-/Abmeldung": "Registrazione/disdetta",
        "Ja, ich nehme teil": "Sì, partecipo",
        "Nein, ich bin verhindert": "No, sono impossibilitato",
        "Ich akzeptiere die": "Accetto il",
        "allgemeinen Geschäftsbedingungen": "Termini e condizioni generali",
        "Die": "Il",
        "Datenschutzerklärung": "Informativa sulla privacy",
        "habe ich gelesen und verstanden": "Ho letto e compreso.",
        "Absenden": "Inviare",
        "Sie müssen die AGB akzeptieren!": "Deve accettare le condizioni generali!",
        "Fragen oder Probleme?": "Domande o problemi?",
        "Geburtsdatum": "Data di nascita",
        "Hallo": "Ciao",
        "Anmeldebestätigung": "Conferma d'iscrizione",
        "Erste Hilfe Kurs": "Corso di Primo Soccorso",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Grazie per esserti iscritto al corso di Primo Soccorso. Ecco ancora una volta le informazioni più importanti",
        "Liebe Grüsse": "Cordiali saluti",
        "Dein Sanio-Team": "Il tuo Team Sanio",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Conferma di modifica della prenotazione",
        "Terminerinnerung": "Promemoria dell'appuntamento",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Il momento è arrivato e il corso di primo soccorso si terrà presto. Ecco ancora una volta le informazioni più importanti:",
        "Deutsch": "Tedesco",
        "Französisch": "Francese",
        "Italienisch": "Italiano",
        "Englisch": "Inglese",
        "Spanisch": "Spagnolo",
        "Portugiesisch": "Portoghese",
        "Kurse": "Corsi",
        "Impersonation beenden": "Terminare l'impersonificazione",
        "course_manuals": "Istruzioni per il corso",
        "employment": "Posizione",
        "habe ich gelesen und verstanden.": "Ho letto e compreso.",
        "Instructors": "Istruttori",
        "insurance": "Assicurazione",
        "INSURANCE_FLAT": "Tassa di amministrazione",
        "Kontaktdaten": "Dettagli di contatto",
        "In Kurs verschieben": "Spostare nel corso",
        "Kursleiterinnen": "Istruttori",
        "lessons": "Lezioni di guida",
        "locations": "Luoghi",
        "Mitarbeitende": "Collaboratori",
        "Kursübersicht": "Panoramica del corso",
        "Zurück zum Login": "Torna al login",
        "Passwort bestätigen": "Confermare la password",
        "Dashboard": "Pannello di controllo",
        "In welchen Kurs soll {participantName} verschoben werden?": "In quale corso dovrebbe essere spostato {participantName}?",
        "Mehr anzeigen": "Mostra di più",
        "{participantName} in Kurs {courseName} verschieben?": "Spostare {participantName} nel corso {courseName}?",
        "Zum Kurs": "Al corso",
        "Umbuchungsbestätigung senden": "Inviare la conferma di modifica della prenotazione",
        "Zurück": "Indietro",
        "Verschieben": "Spostare",
        "Willkommen {name} 👋": "Benvenuto/a {name} 👋",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Il file {fileName} è stato importato {statusHint}.",
        "erfolgreich": "con successo",
        "exams": "Esami",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Se si utilizza Excel per l'importazione, assicurarsi che le colonne, come p.es. la data di nascita, siano formattate come testo.",
        "Fehlermeldung": "Messaggio di errore",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Le righe seguenti non possono essere importate a causa di dati non validi (<span class=\"text-red\"></span>contrassegnati in rosso):",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Le righe seguenti sono state comunque importate a causa di un'assegnazione poco chiara.",
        "Handynummer": "Numero di cellulare",
        "app": "App di teoria",
        "absences": "Assenze",
        "comments": "Commenti",
        "course_documents": "Documenti del corso",
        "All": "Tutti",
        "Spalte": "Colonna",
        "students": "Alunni",
        "Teachers": "Istruttori di guida",
        "Teilnehmerübersicht öffnen": "Aprire la panoramica dei partecipanti",
        "teilweise": "in parte",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "L'{attribute} è già apparso in una fuga di dati. Selezionarne un altro {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} deve essere composto da almeno un carattere.",
        "The {attribute} must contain at least one number.": "{attribute} dev'essere composto da almeno un numero.",
        "The {attribute} must contain at least one symbol.": "{attribute} dev'essere composto da almeno un carattere speciale.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} dev'essere composto da almeno una lettera maiuscola e una minuscola.",
        "vku": "Corsi di teoria di guida",
        "voice_status_end": "Terminato",
        "voice_status_missed": "Mancato",
        "voice_status_out-of-office": "Fuori ufficio",
        "voice_status_queue": "Coda",
        "voice_status_start": "Inizio",
        "voice_status_taken": "in dialogo",
        "voice_status_voicemail": "Messaggio vocale",
        "Zeile": "Linea",
        "Ähnliche TN": "Partecipante simile",
        "Es existieren keine Daten.": "Non esistono dati.",
        "Abbrechen": "Interrompere",
        "Abmelden": "Disconnettersi",
        "certificates.not-yet-known": "Non ancora noto",
        "certificates.sanio": "Conferma del corso Sanio",
        "certificates.src": "Certificato SRC",
        "Email": "E-mail",
        "voice_status_initiated": "Benvenuto/a",
        "Diese Anmeldemaske wurde deaktiviert.": "Questa schermata di accesso è stata disattivata.",
        "errors.4xx.title": "Errore imprevisto",
        "errors.4xx.head": "Ops...",
        "errors.4xx.hint": "Si è verificato un errore. La tua richiesta non può essere elaborata. Per favore, controlla i tuoi dati inseriti o riprova più tardi. L'errore ci è stato segnalato.",
        "errors.5xx.title": "Errore interno del server",
        "errors.5xx.head": "Oh oh...",
        "errors.5xx.hint": "Si è verificato un errore imprevisto. Torna alla pagina iniziale o riprova più tardi. L'errore ci è stato segnalato.",
        "errors.401.title": "Accesso negato",
        "errors.401.head": "Ops…",
        "errors.401.hint": "Non sei autorizzato ad accedere a questa pagina. Effettua il login e riprova.",
        "errors.403.title": "Accesso negato",
        "errors.403.head": "Ops…",
        "errors.403.hint": "Accesso negato. Non sei autorizzato a visitare questa pagina. Se ritieni che si tratti di un errore, contattaci.",
        "errors.404.title": "Pagina non trovata",
        "errors.404.head": "Ops...",
        "errors.404.hint": "La pagina richiamata non esiste. Torna indietro o fai clic sul pulsante per tornare alla pagina iniziale.",
        "errors.500.title": "Errore interno del server",
        "errors.500.head": "Oh oh...",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "La richiesta è durata troppo a lungo. Si prega di riprovare più tardi. L'errore è stato segnalato.",
        "errors.back-to-start": "Alla pagina iniziale",
        "errors.500.hint": "Si è verificato un errore imprevisto. Torna alla pagina iniziale o riprova più tardi. L'errore è stato segnalato.",
        "errors.503.title": "Modalità di manutenzione",
        "errors.503.head": "Un momento per favore...",
        "errors.503.hint": "Stiamo effettuando una manutenzione per apportare miglioramenti e correzioni di bug per migliorare l'esperienza dell'utente. Si prega di riprovare a breve. Grazie per la comprensione.",
        "errors.503.hint2": "In caso di urgenza, potete contattarci via e-mail all'indirizzo <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> o telefonicamente al numero <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Timeout",
        "{attribute} is keine gültige Sprache.": "{attribute} non è un linguaggio valido.",
        "Fehler beim Laden der Kurse": "Errore nel caricamento dei corsi",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Sei stato spostato nel corso \"{courseName}\".",
        "Die aktuellen Infos lauten:": "Ecco le nuove informazioni:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Per qualsiasi domanda puoi contattare direttamente {name}:",
        "oder": "o",
        "Kursort:": "Sede del corso:",
        "Kursdaten": "Date del corso",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Presto parteciperai al \"{courseName}\".",
        "Kurs-ID": "ID del corso",
        "Hier nochmals die wichtigsten Infos:": "Ecco di nuovo le informazioni più importanti:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "L'iscrizione al corso \"{courseName}\" è stata effettuata con successo 🎉",
        "Zum Kalender hinzufügen": "Aggiungi al calendario",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Non è stato fornito alcun indirizzo e-mail o numero di cellulare.",
        "Die E-Mail-Adresse ist ungültig.": "L'indirizzo e-mail non è valido",
        "Die Handynummer ist ungültig.": "Il numero di cellulare non è valido.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Purtroppo non è possibile registrarsi con questa e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Purtroppo non è possibile registrarsi con questo numero di telefono.",
        "Der Code ist ungültig.": "Il codice non è valido.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Grazie per esserti iscritto al corso di Primo Soccorso. Ecco ancora una volta le informazioni più importanti:",
        "Der Kurs wurde dupliziert 🎉": "Il corso è stato duplicato 🎉",
        "Kurs": "Corso",
        "Text wurde in die Zwischenablage kopiert": "Il testo è stato copiato negli appunti",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Non è stato possibile copiare il testo negli appunti",
        "Der Katalog wurde erstellt.": "È stato creato il catalogo.",
        "Der Katalog wurde aktualisiert.": "Il catalogo è stato aggiornato.",
        "Der Katalog wurde entfernt.": "Il catalogo è stato rimosso.",
        "Das Thema wurde erstellt.": "Il modulo è stato creato.",
        "Das Thema wurde aktualisiert.": "Il modulo è stato aggiornato.",
        "Das Thema wurde entfernt.": "Il modulo è stato rimosso.",
        "Story wurde erfolgreich kopiert 🎉": "La storia è stata copiata con successo 🎉",
        "Geschlecht": "Genere",
        "Eine Variante|{count} Varianten": "Una variante|{count} Varianti",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "L'area eLearning non è disponibile per questo cliente.",
        "Männlich": "Maschile",
        "Weiblich": "Femminile",
        "Divers": "Altro",
        "Projektstandort": "Sede del progetto",
        "Wohnkanton": "Cantone di residenza",
        "Sanio Logo": "Logo Sanio",
        "hier": "qui",
        "Hallo {name} 👋": "Ciao {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Ti inviamo nuovamente i dettagli più importanti:",
        "Teilnehmer:innen": "Partecipanti",
        "Name": "Nome",
        "Letzter Kurs": "Ultimo corso",
        "Nächster Kurs": "Il prossimo corso",
        "Hinzufügen": "Aggiungi",
        "Exportieren": "Esportare",
        "Keinem Kurs zugeordnet": "Non assegnato a nessun corso",
        "Suchbegriff": "Termine di ricerca",
        "Dein Sanio Team": "Il tuo Team Sanio",
        "Alle": "Tutti",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Qui trovi i nostri <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">termini e condizioni generali</a> e la nostra <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">informativa sulla privacy</a>.",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Se non desideri più ricevere e-mail da questa lista, puoi annullare l'iscrizione <a href=\"{unsubscribeListUrl}\" target=\"_blank\">qui</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Non vuoi più ricevere offerte da parte nostra? Puoi annullare l'iscrizione <a href=\"{unsubscribeUrl}\" target=\"_blank\">qui</a>.",
        "Ungruppierte Kurse": "Corsi non raggruppati",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Sono stati trovati più account per questi dati. Per favore, seleziona il cliente desiderato.",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Disconnettere il/la partecipante / rimuovere dal corso",
        "eLearning Einladung verschicken": "Inviare un invito eLearning",
        "Anmeldebestätigung erneut zustellen": "Inoltrare nuovamente la conferma della registrazione",
        "Einladung zum eLearning": "Invito all'eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "Siamo lieti di informarti che l’eLearning è ora disponibile! Puoi iniziare subito a imparare! 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "Entro il <strong>{courseName}</strong> al <strong>{courseStart}</strong> presso <strong>{coursePlace}</strong> devi assolutamente aver completato l'eLearning. Serve come base per il corso pratico.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "L'eLearning dura circa 2.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "È possibile dompletare l’eLearning in un’unica sessione o in più sessioni.",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "Alla fine c'è una breve verifica di apprendimento. Pertanto, vale la pena studiare attentamente i contenuti!",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Vuoi davvero disiscrivere il/la partecipante dal corso / rimuovere dal corso?",
        "Abmelden / entfernen": "Disconnettere / rimuovere",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Volete davvero inviare un invito eLearning?",
        "Einladung verschicken": "Inviare l'invito",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Vuoi davvero inviare nuovamente la conferma di registrazione?",
        "Bestätigung erneut verschicken": "Inviare nuovamente la conferma",
        "Teilnehmer:in abmelden": "Annullare l'iscrizione del/della partecipante",
        "Bemerkungen": "Osservazioni",
        "Wert fehlt!": "Valore mancante!",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} Non sono stati creati partecipanti e {totalUpdated} sono stati aggiornati.|{1} È stato creato un partecipante e aggiornati {totalUpdated}.|[2,*] Sono stati creati {count} partecipanti e aggiornati {totalUpdated}.",
        "Zu Kursgruppe hinzufügen": "Aggiungi al corso/gruppo",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "Quanto è stata difficile per te la verifica finale d'apprendimento?",
        "Überspringen": "Saltare",
        "Neue Kursgruppe erstellen": "Crea un nuovo gruppo",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Vuoi davvero cancellare il corso definitivamente? Questa operazione è irreversibile.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Vuoi davvero archiviare il corso? Il corso non sarà più visibile, ma potrà essere ripristinato da un amministratore.",
        "Freigabelink löschen": "Eliminare il link di condivisione",
        "Möchtest du den Freigabelink wirklich löschen?": "Vuoi davvero eliminare il link di condivisione?",
        "Archivieren": "Archiviare",
        "Endgültig löschen": "Cancellare definitivamente",
        "Gruppe löschen": "Cancellare il gruppo",
        "Möchtest du diese Gruppe wirklich löschen?": "Vuoi davvero cancellare questo gruppo?",
        "Kurs aus Gruppe entfernen": "Rimuovere il corso dal gruppo",
        "Kurs duplizieren": "Duplicare il corso",
        "Kurs archivieren": "Archiviare il corso",
        "Kurs löschen": "Cancellare il corso",
        "Der Freigabelink wurde gelöscht! 🎉": "Il link di condivisione è stato cancellato! 🎉",
        "Link teilen": "Condividere il link",
        "Gruppe bearbeiten": "Modificare il gruppo",
        "Neue:n Teilnehmer:in anmelden": "Registrare nuovi partecipanti",
        "nimmt teil": "partecipa",
        "Ähnliche Teilnehmer:innen": "Partecipanti simili",
        "Ein unerwarteter Fehler ist aufgetreten": "Si è verificato un errore imprevisto",
        "Bereits in diesem Kurs vorhanden": "Già presente in questo corso",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "Non sono stati trovati partecipanti simili.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Compila il modulo e la ricerca avrà inizio.",
        "Suche ähnliche Teilnehmer:innen...": "Cerca partecipanti simili...",
        "eLearning nicht gefunden": "eLearning non trovato",
        "Wie hat dir das eLearning allgemein gefallen?": "Cosa ne pensi dell'eLearning in generale?",
        "🤩 Sehr gut!": "🤩 Molto bene!",
        "😊 Gut": "😊 Buono",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Non così bene",
        "😩 Schlecht": "😩 Male",
        "Bitte wähle eine Antwort aus.": "Seleziona una risposta.",
        "Wie verständlich waren für dich die Lerninhalte?": "Quanto è stato comprensibile per te il contenuto dell‘eLearning?",
        "🤩 Sehr verständlich!": "🤩 Molto comprensibile!",
        "😊 Verständlich": "😊 Comprensibile",
        "😕 Nicht so verständlich": "😕 Non così comprensibile",
        "😩 Gar nicht verständlich": "😩 Per niente comprensibile",
        "🤩 Sehr einfach!": "🤩 Molto semplici!",
        "😊 einfach": "😊 semplici",
        "😐 Nicht so einfach": "😐 Non così facili",
        "😕 Schwierig": "😕 Difficili",
        "😩 Sehr schwierig": "😩 Molto difficili",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "Come valuti la facilità d'uso dell'eLearning?",
        "🤩 Super einfache Bedienung!": "🤩 Funzionamento semplicissimo!",
        "😊 Einfach zu bedienen": "😊 Facile da usare",
        "😐 Teilweise etwas mühsam": "😐 A volte un po' impegnativo",
        "😩 Richtig mühsame Bedienung": "😩 Utilizzo davvero impegnativo",
        "Warum war die Lernkontrolle für dich einfach?": "Perché la verifica d'apprendimento è stata facile per te?",
        "Warum war die Lernkontrolle für dich schwierig?": "Perché la verifica d'apprendimento è stata difficile per te?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "Come possiamo migliorare l'eLearning? Cosa ti è mancato?",
        "Würdest du das eLearning weiterempfehlen?": "Consiglieresti l'eLearning ad altri?",
        "🤩 Ja klar!": "🤩 Sì, certo!",
        "😊 Ja, wahrscheinlich schon": "😊 Sì, probabilmente",
        "😐 Weiss nicht recht": "😐 Non saprei",
        "😕 Eher nicht": "😕 Tendenzialmente no",
        "😩 Nein, auf keinen Fall": "😩 No, assolutamente no",
        "Vielen Dank für deine Antworten!": "Grazie mille per le tue risposte!",
        "Cool hast du dir die Zeit genommen!": "Fantastico che ti sei preso/a il tempo!",
        "Fehler beim Laden der Suchergebnisse": "Errore nel caricamento dei risultati della ricerca",
        "Speichern": "Salva",
        "Jetzt mit dem eLearning starten": "Inizia adesso con l’eLearning",
        "Zu Kurs zugeordnet": "Assegnato al corso",
        "Teilnehmer:innen {company}": "Partecipanti {company}",
        "Dein my Sanio Login Code": "Il vostro codice di accesso my Sanio",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, non dimenticare l'eLearning per il corso di {company}!",
        "Dein my.sanio Verifizierungscode": "Il tuo codice di verifica my.sanio",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "Le CG e l’informativa sulla privacy devono essere accettate.",
        "Der Kurs ist leider bereits ausgebucht.": "Purtroppo, il corso è già al completo.",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "Accetto i {tos} e ho letto e compreso la {privacyPolicy}.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Purtroppo, non siamo riusciti a iscriverti al corso.",
        "Persönliche Daten": "Dati personali",
        "Zukünftige Kurse": "Corsi futuri",
        "Vergangene Kurse": "Corsi passati",
        "eLearning": "eLearning",
        "Fortschritt:": "Progresso:",
        "Dauer:": "Durata:",
        "Kommunikation": "Comunicazione",
        "Kanal": "Canale",
        "unbekannter Betreff": "Oggetto sconosciuto",
        "Zeit": "Tempo",
        "Status": "Stato",
        "Inhalt": "Contenuto",
        "Von:": "Da:",
        "An:": "A:",
        "CC:": "CC:",
        "BCC:": "CCN:",
        "Antwort an:": "Risposta a:",
        "Anhänge": "Allegati",
        "Profil": "Profilo",
        "Nicht dargestellte Kursdaten": "Dati del corso non visualizzati",
        "Benutzerbild": "Immagine dell’utente",
        "Meine Kurse": "I miei corsi",
        "Dashboard Instruktor:innen": "Pannello di controllo degli istruttori",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "Se troviamo un account per questo indirizzo email, riceverai a breve un'email con un link per accedere (per favore controlla anche la tua cartella spam).",
        "Jetzt einloggen": "Accedi ora",
        "Link zum Einloggen": "Link per il login",
        "Teilnahme-Status erfolgreich aktualisiert.": "Stato di partecipazione aggiornato con successo.",
        "Team": "Team",
        "Anwesenheit": "Presenza",
        "Gemäss Kursleiter:in anwesend": "Presente secondo l’istruttore",
        "Gemäss Kursleiter:in abwesend": "Assente secondo l’istruttore",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 Non hai corsi questa settimana.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 Non hai corsi futuri.",
        "😌 Du hast keine vergangenen Kurse.": "😌 Non hai corsi passati.",
        "Diese Woche": "Questa settimana",
        "und": "e",
        "Geschäftsführerin Sanio AG": "CEO Sanio AG",
        "Zertifikat": "Certificato",
        "Teilnahmebestätigung": "Conferma di partecipazione",
        "Kursleitung:": "Responsabile del corso:",
        "Dieses Zertifikat ist bis am {date} gültig.": "Questo certificato è valido fino al {date}.",
        "Eine Auffrischung der Erste Hilfe Grundlagen alle 2 Jahre wird empfohlen.": "Ogni 2 anni si raccomanda un corso di aggiornamento sulle nozioni di base del primo soccorso.",
        "Download fehlgeschlagen.": "Download fallito.",
        "Generiere Teilnahmebestätigungen...": "Genera certificati di partecipazione...",
        "Teilnahmebestätigungen wurden erfolgreich generiert und heruntergeladen.": "Le conferme di partecipazione sono state generate e scaricate con successo.",
        "Fehler beim Erstellen der Teilnahmebestätigungen.": "Errore durante la creazione delle conferme di partecipazione.",
        "Generiere Teilnahmebestätigung...": "Generare la conferma di partecipazione...",
        "Teilnahmebestätigung wurde erfolgreich generiert und heruntergeladen.": "La conferma di partecipazione è stata generata e scaricata con successo.",
        "Download wird gestartet...": "Il download sta per iniziare…",
        "Download erfolgreich abgeschlossen.": "Download completato con successo.",
        "Teilnahmebestätigungen als PDF": "Conferma di partecipazione in formato PDF",
        "Teilnahmebestätigungen als ZIP": "Conferma della partecipazione come ZIP",
        "Fehler beim Erstellen der Teilnahmebestätigung.": "Errore durante la creazione della conferma di partecipazione.",
        "Teilnehmerliste exportieren": "Esporta l'elenco partecipanti",
        "wurde erstellt": "è stato creato",
        "kommentierte": "ha commentato",
        "Schreibe deinen Kommentar...": "Scrivi il tuo commento...",
        "Zurücksetzen": "Reimpostare",
        "Kommentieren": "Commentare",
        "Kommentar bearbeiten": "Modificare commento",
        "Bearbeiten": "Modificare",
        "Löschen": "Eliminare",
        "Aktivität": "Attività",
        "Teilnehmer:in hinzufügen": "Aggiungi un partecipante",
        "Teilnahmebestätigung herunterladen": "Scarica la conferma di partecipazione",
        "Teilnehmer abgleichen": "Sincronizzare i partecipanti",
        "Dieses Objekt": "Questo oggetto",
        "Der Kurs ist ausgebucht.": "Il corso è al completo.",
        "abgeschlossen": "completato",
        "Der Kurs ist bereits beendet.": "Il corso è già terminato.",
        "{capacity} Teilnehmende": "{capacity} Partecipanti",
        "{availableSpots} Plätze frei": "{availableSpots} posti disponibili",
        "Es sind keine anderen Kurse verfügbar.": "Non sono disponibili altri corsi.",
        "Teilnehmer wurde in neuen Kurs verschoben.": "Il/la partecipante è stato/a spostato/a in un nuovo corso.",
        "Beendete Kurse können nicht mehr bearbeitet werden.": "I corsi conclusi non possono più essere modificati.",
        "certificates.none": "Nessun certificato",
        "Der Benutzer wurde erfolgreich erstellt.": "L'utente è stato creato con successo.",
        "vollständig": "completo",
        "Nachrichtenverlauf": "Cronologia dei messaggi",
        "Teilnehmer wurde gelöscht!": "Il partecipante è stato rimosso!",
        "Kursgruppe erfolgreich aktualisiert 🎉": "Gruppo di corsi aggiornato con successo 🎉",
        "Der Kurs wurde gespeichert 🎉": "Il corso è stato salvato 🎉",
        "Der Kundenbereich wurde eröffnet! 🎉": "L'area clienti è stata aperta! 🎉",
        "Kurstyp wurde erfolgreich aktualisiert.": "Il tipo di corso è stato aggiornato con successo.",
        "Kurse wurden der Gruppe hinzugefügt.": "I corsi sono stati aggiunti al gruppo.",
        "Der Import wurde gestartet und wird im Hintergrund ausgeführt.": "L'importazione è stata avviata e viene eseguita in background.",
        "Der Benutzer wurde erfolgreich gelöscht.": "L'utente è stato eliminato con successo.",
        "Teilnehmer:in anzeigen": "Mostra partecipanti",
        "Die Offerte wurde erstellt.": "L'offerta è stata preparata.",
        "Teilnehmer erfolgreich gespeichert!": "Partecipante salvato con successo!",
        "Der Kundenbereich wurde gespeichert! 🎉": "L'area clienti è stata salvata! 🎉",
        "Kursgruppe wurde gelöscht!": "Il gruppo di corsi è stato cancellato!",
        "Kursgruppe erfolgreich erstellt 🎉": "Gruppo di corsi creato con successo 🎉",
        "Möchtest du das Element wirklich löschen?": "Si vuole davvero eliminare l'elemento?",
        "Auswählen": "Selezionare",
        "Kurs erstellen": "Creare un corso",
        "Mail Statusinformationen wurden aktualisiert.": "Le informazioni sullo stato della posta sono state aggiornate.",
        "Das Unternehmen wurde erstellt.": "L'azienda è stata creata.",
        "Der Kontakt wurde erstellt.": "Il contatto è stato creato.",
        "Teilnehmer wurde in den neuen Kurs verschoben.": "Il partecipante è stato spostato nel nuovo corso.",
        "Der Kurs wurde erfolgreich gelöscht!": "Il corso è stato cancellato con successo!",
        "Kurstypen": "Tipi di corso",
        "Keine Daten gefunden.": "Non sono stati trovati dati.",
        "Kurstyp bearbeiten": "Modifica del tipo di corso",
        "Kurs hat noch nicht begonnen.": "Il corso non è ancora iniziato.",
        "Kurs bearbeiten": "Elabora il corso",
        "HINWEIS:": "AVVISO:",
        "Das Angebot wurde aktualisiert.": "L'offerta è stata aggiornata.",
        "Das Angebot wurde entfernt.": "L'offerta è stata rimossa.",
        "Nachricht wurde erneut zugestellt 🎉": "Il messaggio è stato consegnato di nuovo 🎉",
        "Das Login wurde erstellt! 🎉": "Il login è stato creato! 🎉",
        "Das Login wurde gelöscht! 🎉": "Il login è stato cancellato! 🎉",
        "Nachricht wurde verschickt 🎉": "Il messaggio è stato inviato 🎉",
        "Geteilter Link gelöscht!": "Il link condiviso è stato eliminato!",
        "Der Kurs wurde gespeichert": "Il corso è stato salvato",
        "Der Kurs wurde erfolgreich archiviert!": "Il corso è stato archiviato con successo!",
        "Teilnehmer:in erfolgreich abgemeldet.": "Partecipante cancellato con successo.",
        "Der Benutzer wurde erfolgreich aktualisiert.": "L'utente è stato aggiornato con successo.",
        "Die Weiterleitung wurde erstellt!": "Il corso è stato archiviato con successo!",
        "Die Weiterleitung wurde aktualisiert!": "L'inoltro è stato aggiornato!",
        "Die Weiterleitung wurde gelöscht!": "L'inoltro è stato cancellato!",
        "Das Unternehmen wurde aktualisiert.": "L'azienda è stata aggiornata.",
        "Das Unternehmen wurde gelöscht!": "L'azienda è stata cancellata!",
        "Der Kontakt wurde aktualisiert.": "Il contatto è stato aggiornato.",
        "Der Kontakt wurde entfernt.": "Il contatto è stato rimosso.",
        "Der Kommentar wurde gespeichert.": "Il commento è stato salvato.",
        "Der Kommentar wurde aktualisiert.": "Il commento è stato aggiornato.",
        "Der Kommentar wurde entfernt.": "Il commento è stato rimosso.",
        "Link für gesamte Kursliste kopieren": "Copiare il link per l'elenco completo dei corsi",
        "Link für diese Kursgruppe kopieren": "Copiare il link per questo gruppo di corsi",
        "Willkommen im": "Benvenuto/a al",
        "Instruktor:innen Bereich": "Area Istruttori",
        "Der Login-Link ist ungültig oder abgelaufen.": "Il link di accesso non è valido o è scaduto.",
        "weiter": "continua",
        "+{more} weitere": "+{more} ulteriori",
        "Notfallnummer (für Kurse)": "Numero di emergenza (per i corsi)",
        "Telefonzeiten": "Orari di reperibilità telefonica",
        "Montag bis Freitag": "Da lunedì a venerdì",
        "9.00 bis 12.00 Uhr": "dalle 9.00 alle 12.00",
        "13.00 bis 16.00 Uhr": "dalle 13.00 alle 16.00",
        "Link für diesen Kurs kopieren": "Copiare il link per questo corso",
        "Link für Anmeldung teilen": "Condividi il link per la registrazione",
        "🤔 Es sind keine Kurse geplant.": "🤔 Non sono previsti corsi.",
        "Suchen...": "Ricerca...",
        "Kursinstruktor:in": "Istruttore/istruttrice del corso",
        "Management": "Gestione",
        "Büro": "Ufficio",
        "Teilnahmebestätigung Erste Hilfe Kurs": "Conferma della partecipazione al corso di primo soccorso",
        "Zertifikat BLS AED SRC Kurs": "Certificato del corso BLS AED SRC",
        "Die Datei findest du im Anhang dieser E-Mail.": "Il file è allegato a questa e-mail.",
        "Herzlichen Glückwunsch und weiterhin viel Erfolg!": "Congratulazioni e ti auguriamo ancora tanto successo!",
        "Schule": "Scuola",
        "Berufsschule": "Scuola professionale",
        "Gymnasium": "Liceo",
        "Kita": "Asilo nido",
        "Betrieb": "Operatività",
        "Vereine": "Associazioni",
        "Sonstiges": "Varie",
        "Wir freuen uns, dir dein Zertifikat für den <strong>{courseName}</strong> zu übermitteln.": "Siamo lieti di inviarti il tuo certificato per il <strong>{courseName}</strong>.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für den <strong>{courseName}</strong> zu übermitteln.": "Saremo lieti di inviarti la tua conferma di partecipazione al <strong>{courseName}</strong>.",
        "Zertifikate werden nun generiert und an die Teilnehmer:innen verschickt 🎉": "I certificati sono stati generati e inviati ai partecipanti 🎉",
        "Zertifikat wird nun generiert und an den/die Teilnehmer:in verschickt 🎉": "Il certificato viene generato e inviato al partecipante 🎉",
        "Bestätigung zustellen": "Inviare la conferma",
        "Teilnahmebestätigung per Mail versenden": "Inviare la conferma di partecipazione via e-mail",
        "Willst du wirklich die Teilnahmebestätigung per Mail versenden?": "Vuoi davvero inviare la conferma di partecipazione via e-mail?",
        "Teilnahmebestätigungen an Teilnehmer versenden": "Inviare le conferme di partecipazione ai partecipanti",
        "Teilnahmebestätigungen per Mail zustellen": "Inviare le conferme di partecipazione via e-mail",
        "Möchtest du die Teilnahmebestätigungen an alle Teilnehmer:innen zustellen?": "Vuoi inviare la conferma di partecipazione a tutti i partecipanti?",
        "ACHTUNG:": "ATTENZIONE:",
        "Für einige Teilnehmer:innen, die Anspruch auf eine Teilnahmebestätigung haben, liegt keine E-Mail-Adresse vor. Daher kann die Bestätigung an die folgenden Personen nicht per Mail zugestellt werden:": "Per alcuni partecipanti che hanno diritto a una conferma di partecipazione, non è disponibile alcun indirizzo e-mail. Pertanto, la conferma non può essere inviata via e-mail alle seguenti persone:",
        "Füge deine E-Mail-Adresse ein, um eine Anmeldebestätigung und ein Erinnerungsmail zu erhalten.": "Inserisci il tuo indirizzo e-mail per ricevere la conferma di registrazione e un'e-mail di promemoria.",
        "Du bekommst von uns einen Link per E-Mail zugesendet, um dein Passwort zurückzusetzen. Bitte gib deine E-Mail-Adresse ein, die du zum Login im Sanio Kundenportal verwendest.": "Riceverai un link via e-mail per reimpostare la password. Inserisci l'indirizzo e-mail che hai utilizzato per accedere al portale clienti di Sanio.",
        "Sanio Kundenbereich": "Area clienti Sanio",
        "Liebe Geschäftskund:innen, unser Portal für die Kursverwaltung hat einen neuen Namen. Das {portalName} erreicht ihr ab sofort unter {sanioPortalLink}.": "Cari clienti commerciali, il nostro portale per la gestione dei corsi ha un nuovo nome. Ora è possibile raggiungere il {portalName} all'indirizzo {sanioPortalLink}.",
        "Hier dein persönlicher Zugang, um dich im Sanio Kundenportal einzuloggen:": "Ecco il tuo accesso personale per accedere al portale clienti Sanio:",
        "Hier dein persönlicher Zugang, um dich im Sanio Instruktor:innen Bereich einzuloggen:": "Ecco il tuo accesso personale per effettuare il login nell’area istruttori di Sanio:",
        "Teilnahmebestätigungen herunterladen": "Scaricare le conferme di partecipazione",
        "Hier dein persönlicher Zugang, um dich im my.sanio einzuloggen:": "Ecco il tuo accesso personale per effettuare il login su my.sanio:",
        "Wichtige Hinweise:": "Avvisi importanti:",
        "Der Link kann nur einmal verwendet werden.": "Il link può essere utilizzato una sola volta.",
        "Er ist zeitlich begrenzt gültig.": "È valido per un periodo di tempo limitato.",
        "Falls das Login nicht funktioniert, kannst du jederzeit einen neuen Link auf der <a href=\"{loginPageUrl}\">Anmeldeseite</a> anfordern.": "Se il login non funziona, puoi richiedere un nuovo link in qualsiasi momento sulla <a href=\"{loginPageUrl}\">pagina di accesso</a>.",
        "in Planung": "in pianificazione",
        "Füge eine persönliche E-Mail-Adresse ein, damit der:die Teilnehmer:in alle kursbezogenen Mitteilungen (z. B. Anmeldebestätigung, Erinnerung, Teilnahmebestätigung) direkt per Mail erhält.": "Inserisci un indirizzo e-mail personale in modo che il/la partecipante riceva tutte le comunicazioni relative al corso (ad es. conferma dell'iscrizione, promemoria, conferma della partecipazione) direttamente via e-mail.",
        "am <strong>{date}</strong>": "il <strong>{date}</strong>",
        "am <strong>{dateStart}</strong> und <strong>{dateEnd}</strong>": "il <strong>{dateStart}</strong> e <strong>{dateEnd}</strong>",
        "vom <strong>{dateStart}</strong> bis <strong>{dateEnd}</strong>": "dalle <strong>{dateStart}</strong> alle <strong>{dateEnd}</strong>",
        "Dein Sanio-Verifizierungscode": "Il tuo codice di verifica Sanio",
        "Dein Verifizierungscode lautet: <strong>{code}</strong>": "Il codice di verifica è: <strong>{code}</strong>",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im Sanio Kundenportal mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Se è stata salvata una password, il contatto può accedere al portale clienti Sanio con email e password. Altrimenti, verrà inviato un link di accesso.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im team.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Se è stata impostata una password, il contatto può accedere a team.sanio con il proprio indirizzo e-mail e la password. Altrimenti, viene inviato un link di accesso.",
        "Ist ein Passwort hinterlegt, kann sich der Kontakt im my.sanio mit E-Mail und Passwort anmelden. Ansonsten wird ein Anmeldelink verschickt.": "Se è stata impostata una password, il contatto può accedere a my.sanio con il proprio indirizzo e-mail e la password. Altrimenti, viene inviato un link di accesso.",
        "Einzelkund:in wurde erfolgreich erstellt.": "Il cliente individuale è stato creato con successo.",
        "Einzelkund:in erfassen": "Registrazione del cliente individuale",
        "Einzelkund:in bearbeiten": "Modifica del cliente individuale",
        "Allgemeine Informationen": "Informazioni generali",
        "Rechte": "Diritti",
        "Rollen": "Ruoli",
        "Rollen auswählen": "Selezionare i ruoli",
        "Authentifizierung": "Autenticazione",
        "Passwort (optional)": "Password (opzionale)",
        "Wechseln": "Cambiare",
        "Sprache": "Lingua",
        "Benutzeroberfläche": "Interfaccia utente",
        "total": "totale",
        "übersetzt": "Tradotto",
        "Es gab ein technisches Problem.": "C'è stato un problema tecnico.",
        "ausstehend": "pendente",
        "Übersetzungsstatistiken werden geladen": "Vengono caricate le statistiche di traduzione",
        "Übersetzungsstatistiken konnten nicht geladen werden.": "Non è stato possibile caricare le statistiche di traduzione.",
        "Mögliche Gründe:": "Possibili motivi:",
        "Die Story wurde noch nicht ins Weblate übertragen.": "La storia non è ancora stata trasferita su Weblate.",
        "Übersetzungsstatistiken": "Statistiche di traduzione",
        "Du musst die AGB akzeptieren!": "È necessario accettare i termini e le condizioni generali!",
        "Falls du Excel für den Import verwendest, achte darauf, dass Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Se utilizzi Excel per l'importazione, assicurati che colonne come la data di nascita siano formattate come testo.",
        "Du musst die AGB akzeptieren, um fortfahren zu können.": "Per continuare è necessario accettare i termini e le condizioni generali.",
        "Bitte gib eine gültige Handynummer ein.": "Inserisci un numero di cellulare valido.",
        "Möchtest du die Variante wirklich duplizieren?": "Vuoi davvero duplicare la variante?",
        "Es gibt bereits {count} ähnliche:n Teilnehmer:in. Schalte das Matching aus um den Teilnehmer trotzdem zu erstellen.": "Ci sono già {count} partecipanti simili. Disattiva il matching per creare comunque il partecipante.",
        "Vielen Dank! Wir haben deine Angaben erhalten.": "Grazie mille! Abbiamo ricevuto i tuoi dati.",
        "Deine Ansprechperson:": "La tua persona di contatto:",
        "Deine Ansprechpersonen:": "Le tue persone di contatto:",
        "Preis": "Prezzo",
        "Produkt erfassen": "Inserire il prodotto",
        "Produkt bearbeiten": "Modifica del prodotto",
        "Produktstatus": "Stato del prodotto",
        "Preis in CHF": "Prezzo in CHF",
        "MwSt. in Prozent": "IVA in percentuale",
        "Produktmedien": "Media del prodotto",
        "Produktbild": "Immagine del prodotto",
        "Produkte": "Prodotti",
        "Produkt wurde erstellt.": "Il prodotto è stato creato.",
        "Produkt wurde aktualisiert.": "Il prodotto è stato aggiornato.",
        "Produktgruppen": "Gruppi di prodotti",
        "Produktgruppe erfassen": "Inserire il gruppo di prodotti",
        "Produktgruppe bearbeiten": "Modifica del gruppo di prodotti",
        "Es gibt noch keine Einträge.": "Non ci sono ancora voci.",
        "Medien": "Media",
        "Produktgruppenbild": "Immagine del gruppo di prodotti",
        "Produktgruppe wurde erstellt.": "È stato creato un gruppo di prodotti.",
        "Produktgruppe wurde aktualisiert.": "Il gruppo di prodotti è stato aggiornato.",
        "Produktgruppe": "Gruppo di prodotti",
        "Produkte werden im Hintergrund aktualisiert.": "I prodotti vengono aggiornati in background.",
        "Jetzt aktualisieren": "Aggiornare ora",
        "Die Produktdaten werden aus Bexio regelmässig importiert und mit den bestehenden abgeglichen.": "I dati dei prodotti vengono regolarmente importati da Bexio e sincronizzati con i dati esistenti.",
        "Kürzel (Slug)": "Abbreviazione (Slug)",
        "Produktart (Bexio)": "Tipo di prodotto (Bexio)",
        "Shop wurde erstellt.": "È stato creato il negozio.",
        "Shop wurde aktualisiert.": "Il negozio è stato aggiornato.",
        "Shop wurde gelöscht.": "Il negozio è stato cancellato.",
        "Shop erfassen": "Entra nel negozio",
        "Shop bearbeiten": "Modifica negozio",
        "Shops": "Negozi",
        "Aktiv": "Attivo",
        "Produktgruppen auswählen": "Selezionare i gruppi di prodotti",
        "Titel": "Titolo",
        "Mit der Registrierung akzeptiere ich die {tos}. Die {privacyPolicy} habe ich gelesen und verstanden.": "Con la registrazione, accetto i {tos}. Ho letto e compreso la {privacyPolicy}.",
        "Es muss eine gültige Handynummer angegeben werden.": "È necessario fornire un numero di cellulare valido.",
        "inkl. {taxPercentage}% MwSt.": "incl. {taxPercentage}% di IVA.",
        "Preis (inkl. {taxPercentage}% MwSt)": "Prezzo (incl. {taxPercentage}% IVA)",
        "Bestelldatum: {orderDate}": "Data ordine: {orderDate}",
        "Bestellnummer: {orderReference}": "Numero d'ordine: {orderReference}",
        "Keine Teilnehmenden|ein:e Teilnehmer:in|{capacity} Teilnehmende": "Nessun partecipante|Un/a partecipante|{capacity} partecipanti",
        "Kursmodi": "Modalità del corso",
        "Die Anmeldefrist ist abgelaufen.": "Il termine d‘iscrizione è scaduto.",
        "Kunde": "Cliente",
        "Kein Datum definiert": "Nessuna data definita",
        "Anmeldefrist abgelaufen": "Termine di registrazione scaduto",
        "Anmeldefrist:": "Termine d'iscrizione:",
        "Anmeldefrist": "Termine d‘iscrizione",
        "Der Link wurde in die Zwischenablage kopiert! 🎉": "Il link è stato copiato negli appunti! 🎉",
        "Link": "Link",
        "eLearning öffnen": "Aprire l‘eLearning",
        "Du hast dich erfolgreich für das Erste Hilfe eLearning von {customer} angemeldet! 🎉": "Ti sei registrato con successo per l'eLearning sul Primo Soccorso di {customer}! 🎉",
        "Es steht dir nun zur Verfügung und du kannst ab sofort mit dem Lernen beginnen.": "Ora è a tua disposizione e puoi iniziare subito a imparare.",
        "Du hast dich für das Erste Hilfe eLearning von {customer} angemeldet, aber noch nicht gestartet. Nutze die Chance, dein Wissen aufzufrischen.": "Vi siete registrati per l'eLearning sul Primo Soccorso di {customer} ma non avete ancora iniziato. Cogliete l'occasione per rinfrescare le vostre conoscenze.",
        "Starte jetzt dein Erste Hilfe eLearning! 🚀": "Inizia subito l'eLearning sul primo soccorso! 🚀",
        "Nachricht konnte nicht erneut zugestellt werden!": "Il messaggio non può essere inviato nuovamente!",
        "Möchtest du die Teilnahmebestätigungen herunterladen?": "Vuoi scaricare i certificati di partecipazione?",
        "Einzelkunden": "Clienti privati",
        "Einzelkunden erfassen": "Registrare i clienti privati",
        "Das eLearning dauert rund {onlineCourseDuration}. Am Ende des eLearnings gibt es eine kurze Lernkontrolle. Es lohnt sich also, die Theorie nicht einfach durchzuklicken!": "L'eLearning dura circa {onlineCourseDuration}. Alla fine dell'eLearning è prevista una breve verifica dell'apprendimento. Vale quindi la pena di non limitarsi a cliccare sulla teoria!",
        "Du hast das Erste Hilfe eLearning von {customer} erfolgreich abgeschlossen – grossartig!": "Avete completato con successo l'eLearning sul primo soccorso di {customer} - ottimo!",
        "Danke, dass du dich für Erste Hilfe weitergebildet hast. Bleib sicher!": "Grazie per aver approfondito la tua formazione nel Primo Soccorso. Rimani al sicuro!",
        "Der Kommentar konnte nicht gespeichert werden.": "Non è stato possibile salvare il commento.",
        "{firstname}, dein Erste Hilfe eLearning von {company} wartet auf dich! ⏳": "{firstname}, il tuo eLearning sul Primo Soccorso di {company} ti sta aspettando! ⏳",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning! 🚀": "{firstname}, inizia subito il tuo eLearning sul primo soccorso! 🚀",
        "Glückwunsch, {firstname}! Dein Erste Hilfe eLearning von {company} ist abgeschlossen 🎓": "Congratulazioni, {firstname}! Il vostro corso di primo soccorso di {company} è completo 🎓",
        "{firstname}, dein Zugang zum Erste Hilfe eLearning von {company} ist da!": "{firstname}, il tuo accesso al First Aid eLearning di {company} è qui!",
        "Twint": "Twint",
        "Visa": "Visa",
        "MasterCard": "MasterCard",
        "PostFinance Pay": "PostFinance Pay",
        "Rechnung": "Fattura",
        "Vorkasse": "Pagamento anticipato",
        "Einzelkund:in wurde erfolgreich aktualisiert.": "Il/la cliente privato/a è stato/a aggiornato/a con successo.",
        "Einzelkund:in wurde erfolgreich gelöscht.": "Il/La cliente privato/a è stato/a eliminato/a con successo.",
        "Wie waren die Aufgaben in den Notfällen für dich?": "Come sono stati per te i compiti nelle emergenze?",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa {duration} ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "È ora di iniziare l'eLearning! Vi preghiamo di completarlo entro l'inizio del corso all'indirizzo <strong>{courseStart}</strong>, poiché costituisce la base importante del nostro corso. Lasciate circa {duration} per questo, in modo che siate ben preparati e possiate ottenere il massimo dal corso. Non vediamo l'ora di vedervi!",
        "{firstname}, starte jetzt dein Erste Hilfe eLearning von {company}! 🚀": "{firstname}, inizia subito il tuo eLearning sul primo soccorso da {company}! 🚀",
        "Viel Erfolg! 🚀": "Buona fortuna! 🚀",
        "Du hast bereits {courseProgress}% vom Erste Hilfe eLearning geschafft – super! 🎉": "Hai già completato il {courseProgress}% dell'eLearning sul Primo Soccorso - ottimo! 🎉",
        "Bleib dran und lerne weiter, damit du den Kurs erfolgreich abschliessen kannst.": "Continuate a lavorare e a imparare, così da completare il corso con successo.",
        "Weiterlernen": "Continua a imparare",
        "Keine Kursleitung definiert": "Nessuna gestione del corso definita",
        "Hat die Lernkontrolle im eLearning bestanden.": "Hai superato la verifica d'apprendimento nell‘eLearning.",
        "Wir freuen uns, dir deine Teilnahmebestätigung für diesen Onlinekurs zu übermitteln. Die Datei findest du im Anhang dieser E-Mail.": "Siamo lieti di inviarti la conferma di partecipazione a questo corso online. Trovi il file allegato a questa e-mail.",
        "hat am <strong>{dateText}</strong> am {durationText} {courseTypeName} nach SRC Richtlinien 2021 teilgenommen.": "il <strong>{dateText}</strong> ha partecipato al {durationText} {courseTypeName} secondo le linee guida del CRS 2021.",
        "hat am <strong>{dateText}</strong> das {durationText} {courseTypeName} nach SRC Richtlinien 2021 erfolgreich abgeschlossen.": "ha completato con successo il {durationText} {courseTypeName} secondo le linee guida SRC 2021 il <strong>{dateText}</strong>.",
        "{minutes}-minütige": "{minutes}-minuti",
        "{hours}-stündige": "{hours}-ora",
        "{hours}-stündigen": "{hours}-ora",
        "Teilnehmerliste importieren": "Importazione dell'elenco dei partecipanti",
        "Sortierung": "Ordinamento",
        "Die Teilnehmer:innen haben eine Anmeldebestätigung erhalten.": "I partecipanti hanno ricevuto una conferma di iscrizione.",
        "Folgende Zeilen konnten aufgrund ungültiger Daten (<span class=\"text-red\">rot markiert</span>) nicht importiert werden:": "Le righe seguenti non possono essere importate a causa di dati non validi (<span class=\"text-red\">contrassegnato in rosso</span>):",
        "Teilnehmer:innen wurden {statusHint} aus Datei {fileName} in Kurs {course} importiert.": "I partecipanti sono stati importati {statusHint} dal file {fileName} nel corso {course}.",
        "Kategorie-Label": "Etichetta della categoria",
        "Die Teilnehmer:innen aus der Datei \"{fileName}\"\" wurden {statusHint} im Kurs {course} erfasst.": "I partecipanti del file \"{fileName}\" sono stati inseriti {statusHint} nel corso {course}.",
        "Es wurden total:": "Un totale di:",
        "<strong>{count}</strong> neu angelegt": "<strong>{count}</strong> appena creato",
        "<strong>{count}</strong> aktualisiert": "<strong>{count}</strong> aggiornato",
        "<strong>{count}</strong> am Kurs angemeldet": "<strong>{count}</strong> iscritti al corso",
        "messages": {
            "feedback": {
                "moods": {
                    "positive": "Positivo",
                    "negative": "Negativo",
                    "neutral": "Neutro"
                }
            },
            "communication": {
                "notifications": {
                    "channel": {
                        "database": "Banca dati",
                        "twilio": "SMS",
                        "mail": "Email"
                    },
                    "status": {
                        "sent": "Inviato",
                        "sending": "In fase di invio…",
                        "skipped": "Saltato",
                        "failed": "Fallito"
                    }
                }
            },
            "elearning": {
                "stories": {
                    "status": {
                        "done": "Finito",
                        "draft": "In corso",
                        "review": "Recensione",
                        "translation": "In traduzione"
                    },
                    "subject_focus": {
                        "adults": "Adulti",
                        "teenagers": "Adolescenti (10-15)",
                        "children": "Bambini (4-9)",
                        "babies": "Neonati (0-3)"
                    },
                    "subject_focus_list": {
                        "adults": "Adulti",
                        "teenagers": "Adolescenti (10-15)",
                        "children": "Bambini (4-9)",
                        "babies": "Neonati (0-3)"
                    }
                }
            },
            "shop": {
                "products": {
                    "type": {
                        "service": "Servizio",
                        "goods": "Merci"
                    },
                    "status": {
                        "active": "Attivo",
                        "inactive": "Inattivo",
                        "archived": "Archiviato"
                    }
                }
            },
            "general": {
                "duration": {
                    "months": "un mese|{count} mesi",
                    "years": "un anno|{count} anni",
                    "days": "un giorno|{count} giorni"
                }
            }
        }
    }
}
